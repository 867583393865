import { Table } from 'antd'
import React from 'react'
import { AlignType } from 'rc-table/lib/interface'
import { useLocation } from 'react-router-dom';
const ProductMovement = () => {
    const { state } = useLocation() as any;

    const dataSource = [
        {
            key: '1',
            storage_place: 'A1-02-04',
            operation: 'Removal',
            modification: '2020.01.27.17:14:33',
        },
        {
            key: '2',
            storage_place: 'A1-02-05',
            operation: 'Storage',
            modification: '2020.01.30.11:28:10',
        },

    ];

    const columns = [
        {
            title: 'Storage place',
            dataIndex: 'storage_place',
            key: 'storage_place',
            align: 'center' as AlignType,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            align: 'center' as AlignType,
        },
        {
            title: 'Last modification ',
            dataIndex: 'modification',
            key: 'modification',
            align: 'center' as AlignType,
        },
        {
            title: 'Remark ',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center' as AlignType,
        },

    ];
    return (
        <div className="px-4 pt-[4rem]">
            <h1 className="text-2xl mb-6">Product movement</h1>
            <div className='w-1/2 mb-8'>
                <h1 className='text-xl mb-4'>Product data</h1>
                <div>
                    <div className="flex items-center w-full">
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Article no.</p>
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px] h-[42px]'>{state?.item?.article_no}</p>
                    </div>
                    <div className="flex items-center w-full">
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>EAN code:</p>
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px] h-[42px]'>{state?.item?.ean_code}</p>
                    </div>
                    <div className="flex items-center w-full">
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Producer:</p>
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px] h-[42px]'>{state?.item?.producer}</p>
                    </div>
                    <div className="flex items-center w-full">
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Type:</p>
                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px] h-[42px]'>{state?.item?.name}</p>
                    </div>
                </div>

            </div>
            <div className='w-[80%]'>
                <h1 className='text-xl mb-4'>Product movement</h1>
                <div className=' border-black '>
                    <Table dataSource={dataSource} columns={columns} bordered
                    pagination={{ pageSizeOptions: ["10", "20", "30"], showSizeChanger: true }}/>
                </div>

            </div>

        </div>
    )
}

export default ProductMovement
