import { Button, Form, Input, message, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import { addProductGroup, getListProductGroup } from '../../service/auth/AuthService';

const PopupProductGroup = (props: any) => {
    const { isModalVisible, handleCancelSend, handleOKSend, setIsModalVisible, setListDataGroup, setDataGroup, type } = props;
    const [form] = Form.useForm();
    const [isSpin, setIsSpin] = useState(false);
    const [disable, setDisable] = useState<any>(true);
    const cancelResetValue = () => {
        form.resetFields();
        handleCancelSend();
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onFinish = async (values: any) => {
        setIsSpin(true)
        const data = { ...values }
        try {
            const res = await addProductGroup(data)
            message.success(res.data.message);
            setIsModalVisible(false);
            const dataList = await getListProductGroup();

            const dataFilter = dataList.data.results?.map((item: any) => ({
                value: item.uuid,
                label: item.name
            }))

            if (type == 1) {
                setDataGroup(dataList.data.results);
            } else {
                setListDataGroup(dataFilter)
            }
            setIsSpin(false)
        } catch (error) {
            console.log(error)
        }

        setIsModalVisible(false);
        form.resetFields();
    };
    const onValueChange = (event: any = null,) => {
        const values = form.getFieldsValue();
        values.number_of_types = 0;
        values.quantity = 0
        if (values.name === '' ||
            values.name === undefined) {
            setDisable(true);
            return;
        } else {
            setDisable(false);
            return;
        }
    };
    return (
        <>
            <Modal
                visible={isModalVisible}
                destroyOnClose={true}
                onCancel={cancelResetValue}
                onOk={() => {
                    handleOKSend();
                }}
                title="NEW PRODUCT GROUP"
                className=""
                centered
                footer={null}
            >
                <Spin size="large" spinning={isSpin}>
                    <Form
                        name="basic"
                        labelCol={{ span: 32 }}
                        wrapperCol={{ span: 32 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                        form={form}

                    >
                        <Form.Item label="Name:" name="name" rules={[{ required: true, message: 'This filed is required' }]}>
                            <Input placeholder="" onChange={onValueChange} />
                        </Form.Item>
                        {/* <Form.Item
                        label="Number of types:"
                        name="number_of_types"
                        rules={[{ required: true, message: 'This filed is required' }]}
                    >
                        <Input placeholder="" onChange={onValueChange} type={'number'} min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Quantity:"
                        name="quantity"
                        rules={[{ required: true, message: 'This filed is required' }]}
                    >
                        <Input placeholder="" onChange={onValueChange} type={'number'} />
                    </Form.Item> */}
                        <Form.Item wrapperCol={{ offset: 32, span: 32 }}>
                            <div className="w-[200px] mx-auto mt-[2rem]">
                                <Button
                                    className={`w-full min-w-[10rem] border rounded !text-white !h-9 ${!disable ? `bg-button` : 'bg-gray'} ${!disable ? `border-button` : 'border-gray'} `} 
                                    type="primary" htmlType="submit" disabled={disable}>
                                    Save
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>

                </Spin>
            </Modal>
        </>
    )
}

export default PopupProductGroup
