import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input,  message, Space, Spin, Typography } from 'antd';
import { createPlanWareHouse, profileLogin, getPlan } from '../../service/auth/AuthService';
import '../scss/style.scss';
import { useNavigate } from 'react-router-dom';
import { DataViewItem, TypeOption, TypeTotal, TypeValueChange,  } from '../../interface/warehousePlanning.interface';
const { Paragraph } = Typography;
const WarehousePlanning = () => {
	const [dataTable, setDataTable] = useState<any>([]);
	const [dataArray, setDataArray] = useState<any>([]);
	const [disable, setDisable] = useState<any>(false);
	const [isSpin, setIsSpin] = useState(true);
	const [viewRow, setViewRow] = useState<any>(1);
	const [numRow, setNumRow] = useState<number>(1);
	const [nameWH, setNameWH] = useState<string>('');
	const [uuid, setUuid] = useState<string>('');
	const [itemState, setTotalItem] = useState<TypeTotal>({
		isFirstSet: true,
		iniTotal: 0,
		err: false
	});
	const navigate = useNavigate()
	const [form] = Form.useForm();
	const blockInvalidChar = (e: any) => ['e', 'E', '+', '-', '.', ','].includes(e.key);
	const getListData = async () => {
		try {
			const user = await profileLogin()
			const lastData = user.data?.results?.warehu
			if (lastData) {
				setNameWH(lastData?.name)
				setUuid(lastData?.uuid)
				const dataView = await getPlan(lastData?.uuid)
				setIsSpin(false);
				const dataFix = dataView.data.results[0]?.list_row_by_zone.map((item: DataViewItem) => {
					const dataItem = { row: item.name, isSame: item.is_same === 1 ? true : false, columns: String(item.column), levels: item.level, sameName: undefined };
					return dataItem
				})
				setDataArray(dataFix);
			} else {
				onFinish({
					// data: form.getFieldsValue().data
					data : [{ row: 'A1', isSame: true, columns: '1', levels: '1', sameName: undefined }]
				})
				setIsSpin(false);
			}
			setIsSpin(false);
		} catch (error) { setIsSpin(false) }
	}

	const onValueChange = (event: any = null, options: TypeOption = { index: 0, fieldChange: '' }) => {
		const values = form.getFieldsValue();
		values.data = values.data.map((item: TypeValueChange) => {
			item.row = item.row?.trim()
			return item
		})
		onFinish(values, options);
	};



	const onFinish = (values: any, options: any = {}) => {
		const data = values.data?.map((item: any, idx: number) => {
			if (item.row !== '') {
				const columnSame: any[] = [];
				const elements: any[] = [];
				const onlyNumberStrings = Array(Number(item.columns)).fill(item.levels);
				if (item.isSame) {
					item.dataColumn = onlyNumberStrings
				}
				if (item.dataColumn) {
					item.dataColumn.map((e: [], index: number) => {
						if (typeof e === 'string') {
							onlyNumberStrings[index] = e;
							return e;
						} else {
							return item.levels;
						}
					});
				}

				if (options.fieldChange == 'column' && options.index == idx) {
				
					if (+item.columns > item.dataColumn?.length) {
						// item.dataColumn = [...item.dataColumn, ...Array(+item.columns - (dataTable?.length - 1)).fill("1")]
						item.dataColumn = [...item.dataColumn, ...item.dataColumn.fill('1')];
					}
					else {
						item.dataColumn = [...item.dataColumn.filter((d: any, index: number) => {
							return (index < +item.columns )
						})]
						
					}
				}

				for (let j = 0; j < Number(item.columns); j++) {
					columnSame.push(`${item.row}-${j < 9 ? `0${j + 1}` : j + 1}`);
					const level = item.isSame ? Number(item.levels) : item.dataColumn ? Number(item.dataColumn[j])
						? Number(item.dataColumn[j]) : Number(item.levels) : Number(item.levels);
					// Create a array to handle if item.dataColumn element value maybe underfine
					const max = item.dataColumn[j]
					elements[j] = []
					for (let i = 0; i < max; i++) {
						elements[j].push({
							name: `${item.row}-${j < 9 ? `0${j + 1}` : j + 1}-${i <= 9 ? `${i}0` : i}`,
							row: i,
							column: j,
							deleted: !(i < max && i < level),
						});
					}
				}
				return { ...item, elements: elements.map((el: any) => el.reverse()), columnSame };
			}
			else {
				setDisable(true);
				return;
			}
		});
		const duplicateObject = data.reduce((acc: any, item: any) => {
			if (item?.row) {
				acc[item?.row] = acc && acc[item?.row] == undefined ? 0 : acc[item.row] + 1;
				return acc;
			}
		}, {});
		const newArr: { sameName: boolean; row: string }[] = [];
		for (let i = 0; i < data.length; i++) {
			if (duplicateObject[data[i]?.row] > 0) {
				if (!newArr.find(_a => _a.sameName == false && data[i]?.row == _a.row)) { newArr.push({ ...data[i], sameName: false, }); }
				else { newArr.push({ ...data[i], sameName: true, }); }
			}
			else { newArr.push({ ...data[i], sameName: false, }); }
		}
		setDataTable(newArr);
		getTotalItem(newArr)
		form.setFieldValue('data', newArr)
		setDisable(checkDisableCreate(data));
		const itemsFind = newArr.find((items: any) => items.sameName === true);
		if (itemsFind) { setDisable(true); }
	};

	const getTotalItem = (newArr: any) => {
		const { iniTotal, isFirstSet } = itemState
		const total = newArr.reduce((t: any, item: any) => {
			t += item.elements.reduce((sum: any, d: any) => {
				return sum += d.length
			}, 0)
			return t
		}, 0)
		const err = iniTotal > total ? true : false
		setTotalItem({
			isFirstSet: false,
			iniTotal: isFirstSet ? total : iniTotal,
			err
		})
	}

	const checkDisableCreate = (data: any) => {
		let disable = false;
		data.forEach((value: any) => {
			if (!value.isSame && value.dataColumn) {
				value.dataColumn.forEach((dataC: any) => {
					if (dataC <= 0) {
						disable = true;
						return;
					}
				});
				return disable;
			}
			if (!value.isSame && value.levels.indexOf('-') != -1) {
				value.dataColumn.forEach((dataC: any) => {
					if (dataC <= 0 || dataC.indexOf('-') != -1) {
						disable = true;
						return;
					}
				});
				return disable;
			}
			if (
				value.row === '' || value.row === undefined || value.columns === '' || value.columns === undefined || value.columns <= 0
				|| value.levels === '' || value.levels === undefined || value.levels <= 0 || value.isSame === true && value.levels.indexOf('-') != -1
			) {
				disable = true;
				return;
			}
		});
		return disable;
	};

	const handleRowes = (numberRow: any, add: any, remove: any) => {
		setNumRow(numberRow)
		if (numberRow == 0 || viewRow == '') {
			setViewRow(numberRow.replace(/[0]*$/g, ''));
		} else {
			setViewRow(numberRow);
		}
		const nameCharacter = dataTable.length > 0 ? dataTable[0].row.replace(/[1]*$/g, '') : [];
		if (numberRow > dataTable.length) {
			for (let i = 1; i <= numberRow - dataTable.length; i++) {
				add({
					row: `${numberRow - dataTable.length >= 1 ? `${nameCharacter}${dataTable.length + i}` : `${nameCharacter}${dataTable.length}`}`,
					isSame: true,
					columns: '1',
					levels: '1',
					sameName: undefined,
				});
			}
		} else {
			for (let i = dataTable.length; i >= numberRow; i--) {
				remove(i);
			}
			dataTable.splice(Number(-(dataTable.length - numberRow)));
			setDataTable(dataTable);
		}
		onValueChange();
	};
	const handleCreateWH = async () => {
		const dataCreateWH = dataTable.map((item: any,) => {
			let level;
			if (!item.isSame) {
				// Create a array to handle if item.data element value maybe underfine
				const onlyNumberStrings = Array(Number(item.columns)).fill(item.levels);
				if (item.dataColumn) {
					item.dataColumn.map((e: any, index: number) => {
						if (typeof e === 'string') {
							onlyNumberStrings[index] = e;
							return e;
						} else {
							return item.levels;
						}
					});
				}
				level = onlyNumberStrings.join('--');
			} else {
				level = +item.levels;
			}
			return {
				name: item.row,
				column: +item.columns,
				level: level,
				is_same: item.isSame ? 1 : 0,
			};
		});
		// Check if row name have same value => can not save
		const valueArr = dataCreateWH.map(function (item: any) {
			return item.name;
		});
		const isDuplicate = valueArr.some(function (item: any, idx: any) {
			return valueArr.indexOf(item) != idx;
		});

		if (isDuplicate) {
			message.error('Sorry, can not create new warehouse with same row name!');
			return;
		}
		const dataSend = { warehouse_name: nameWH, rows: dataCreateWH, uuid: uuid };
		if (nameWH == '') {
			message.error('Warehouse name can not be null!');
			return;
		}
		try {
			await createPlanWareHouse(dataSend)
			message.success(dataSend?.uuid === "" ? 'Create new warehouse successfully' : 'Update warehouse successfully');
			await getListData()
			setIsSpin(false)
		} catch (error) {
			message.error('Some places might contain products, cannot update the warehouse!')
			setIsSpin(false)
		}
		if (uuid) {
			navigate('/home')
		}
	};
	// popup


	useEffect(() => {
		getListData();
	}, []);

	useEffect(() => {
		const dataValue = { warehouse: nameWH, data: dataArray };
		if (nameWH != '' && dataArray.length > 0) {
			onFinish({
				...dataValue,
				data: dataArray.map((item: any) => {
					if (!item.is_same) {
						item.dataColumn = item.levels.match(/[0-9]+/g);
					}
					return item
				})
			});
			form.setFieldsValue(dataValue);
			setNumRow(dataArray.length)
		}
	}, [dataArray]);
	


	return (
		<>
			{isSpin ?
				<div className='w-full h-full flex justify-center items-center'>
					<Spin size="large" spinning={isSpin} />
				</div>
				:
				<div className=" px-4 pt-[4rem]">
					<Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" form={form}   >
						<h1 className="text-2xl mb-[2.5rem]">Plan your warehouse</h1>
						<div className="w-[30rem]">
							<Form.Item name="warehouse" label="Name of warehouse" className="font-semibold">
								<Input onChange={e => setNameWH(e.target.value)} defaultValue={nameWH} />
							</Form.Item>
						</div>
						<Form.List name="data" >
							{(fields, { add, remove }) => {
								return (
									<>
										<div className="  ">
											<div className="h-[2rem] mb-2 2xl:mb-0 ">
												<Form.Item name="rowes" label="Number of rowes:" className="font-semibold" >
													<div className="w-[5rem] ml-4">
														<Input
															type="number"
															onKeyDown={blockInvalidChar}
															onChange={e => handleRowes(+e.target.value, add, remove)}
															min={1}
															max={99}
															value={numRow}
															
														/>
													</div>
												</Form.Item>
											</div>
											{fields.map(({ key, name, ...restField }, index) => {
												return (
													<div key={key} className="pt-[2.5rem]">
														<Space style={{ display: 'flex', height: '2rem' }} align="baseline" >
															<h1 className="w-[1.5rem]">{index + 1} .</h1>
															<div className=''>
																<Form.Item
																	{...restField}
																	name={[name, 'row']}
																	label="Name of row"
																	rules={[{ required: true, message: 'This field is required a number ', }]}
																	className="w-[14rem] "
																	validateStatus={dataTable[index] && dataTable[index].sameName ? 'error' : ''}
																>
																	<Input
																		// style={{marginLeft:'1rem'}}																		
																		onChange={onValueChange}
																		maxLength={30}
																		name="columns"
																	// disabled={dataTable && dataTable.length > 0 && dataTable[index] && !dataTable[index].isSame ? true : false}
																	// defaultValue={items.name}																																																				
																	/>
																</Form.Item>
															</div>
															<div className='w-[16.5rem]'>
																<Form.Item
																	{...restField}
																	name={[name, 'columns']}
																	label="Number of columns"
																	rules={[{ required: true, message: 'This field is required a number '}]}
																	className="w-[16.5rem]"
																>
																	<Input
																		onKeyDown={blockInvalidChar}
																		type="number"																	
																		onChange={(e) => onValueChange(e, { index, fieldChange: 'column' })}
																		min={1}
																		max={99}
																	// disabled={dataTable && dataTable.length > 0 && dataTable[index] && !dataTable[index].isSame ? true : false}
																	// defaultValue={items.column}
																	/>
																</Form.Item>
															</div>
															<div className="w-[15.5rem]">
																<Form.Item
																	{...restField}
																	name={[name, 'levels']}
																	label="Number of levels"
																	rules={[{ required: true, message: 'This field is required a number ', }]}
																	validateStatus={dataTable[index] && dataTable[index].isSame === true && dataTable[index].levels.indexOf('-') != -1 || dataTable[index].levels === '' ? 'error' : ''}
																	className="w-[15.5rem]"

																>
																	<Input
																		type="number"
																		onKeyDown={blockInvalidChar}
																		onChange={onValueChange}
																		min={1}
																		max={99}
																		disabled={dataTable && dataTable.length > 0 && dataTable[index] && !dataTable[index].isSame ? true : false}
																	/>
																</Form.Item>
															</div>
															<Form.Item
																{...restField}
																name={[name, 'isSame']}
																valuePropName="checked"
															>
																<Checkbox onChange={onValueChange}>
																	Number of levels is the same for each column
																</Checkbox>
															</Form.Item>
														</Space>

														{dataTable && dataTable[index] && !dataTable[index].isSame && (
															<div className="w-full pt-[1rem] pb-[2rem] border-b-2 border-gray">
																<p>Number of levels per column</p>
																<Space
																	align="baseline"
																>
																	<Form.List name={[name, 'dataColumn']}>
																		{fields => (
																			<div className="flex flex-wrap ">
																				{dataTable[index].columnSame.map((field: any, indexColumn: number,) => {
																					return (
																						<div key={indexColumn} className="mr-2 w-[12rem]">
																							<Form.Item
																								{...field}
																								key={indexColumn}
																								label={dataTable[index].columnSame[indexColumn]}
																								name={indexColumn}
																								rules={[
																									{ required: true, message: 'This field is required a number', }]}
																							>
																								<Input
																									type="number"
																									onChange={onValueChange}
																									min={1}
																									defaultValue={dataTable[index].levels}
																								/>
																							</Form.Item>
																						</div>
																					);
																				},
																				)}
																			</div>
																		)}
																	</Form.List>
																</Space>
															</div>
														)}
													</div>
												);
											})}
										</div>
									</>
								);
							}}
						</Form.List>
					</Form>
					<div className="2xl:mt-0 mt-10 w-full ">
						<Paragraph style={{ minWidth: 400, marginTop: 50, }}>
							<div className="mx-auto   border-2 border-gray px-4 pb-0 w-full    ">
								{dataTable?.map((item: any, key: number) => {
									return (
										<>
											<div key={key} className="flex items-center my-4">
												<span className="text-base font-semibold mr-1 w-[2rem] ">
													{key + 1}.
												</span>
												<table className="">
													<tbody className="flex flex-row">
														{item?.elements.map((items: any, index: number) => {
															return (
																<tr key={index} className={`flex flex-col justify-end border-b-[1px]`}>
																	{items.map((dataItem: any, key: number) => {
																		return (
																			<td
																				key={key}
																				className={` ${dataItem.deleted ? 'border-none' : 'border border-b-[0px]  ml-[-1px]'} min-w-[100px]  text-center
																					${!dataItem.deleted && item.elements.length <= 1 ? 'p-4' : ''} border-black px-1 mb-[-1px]`}
																			>
																				<span>{dataItem.deleted ? '' : dataItem.name}</span>
																			</td>
																		);
																	})}
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										</>
									);
								})}

							</div>
							<div>
								<Button
									className={` 2xl:mt-2 mt-5 pb-5 font-medium border rounded !h-9 !text-white ${!disable ? `!bg-button` : '!bg-gray'} ${!disable ? `!border-button` : '!border-gray'} `}
									onClick={handleCreateWH}
									disabled={disable}
								>
									{uuid ? 'Update warehouse' : 'Create warehouse'}
								</Button>
							</div>
						</Paragraph>
					</div>
					{/* <PopupNavigation
						isModalVisible={isModalOpen}
						handleCancel={handleCancel}
						handleOk={handleOk}
					/> */}
				</div>
			}



		</>
	);
};

export default WarehousePlanning;
