export const dataTopMenu = [
	{
		name: 'Home',
		link: '/home',
		icon: 'bx bx-home text-xl',
	},
	{

		name: 'Warehouse planning',
		link: '/warehouse-planning',
		icon: 'bx bx-palette text-xl',
	},
	{

		name: 'Storage label printing',
		link: '/storage-label-printing',
		icon: 'bx bx-folder text-xl',
	},
	{
		name: 'Inventory management',
		link: '/inventory-management',
		icon: 'bx bx-data  text-xl',
		subMenu: [
			{
				title: 'Manage product', link: '/inventory-management/manage-product', icon: 'bx bx-data  text-xl', sub: [
					{
						titleChild: 'Product movement',
						linkChild: '/inventory-management/manage-product/product-movement',
						icon: 'bx bx-data  text-xl',
					},
				]
			},
			{ title: 'Manage product group', link: '/inventory-management/manage-product-groups', icon: 'bx bx-data  text-xl', },
			// { title: 'Storage Removal', link: '/inventory-management/storage-removal', icon: 'bx bx-data  text-xl', },
		]
	},

	{
		icon: 'bx bx-exit text-xl',
		name: 'Storage / Removal',
		link: '/storage-removal',

	},
	{
		icon: 'bx bx-check-square text-xl',
		name: 'Picking',
		link: '/picking',

	},
	{
		icon: 'bx bx-list-ul text-xl',
		name: 'Transfer list',
		link: '/transfer-list',
	},
	{
		icon: 'bx bx-box text-xl',
		name: 'Product receiving',
		link: '/product-receiving',
	},
];
