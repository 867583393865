import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message, Modal, Select, Spin } from 'antd';
import { addProduct, getListProductGroup, getProductAll, } from '../../service/auth/AuthService';
import PopupProductGroup from './PopupProductGroup';
import { useLocation, useNavigate } from 'react-router-dom';

const PopupProductAdd = (props: any) => {
    const { isModalAddProduct, handleCancelSend, handleOKSend,
        setIsModalAddProduct, setProductFilter, setListProduct, productFilter, type, setStatusOpenSearch ,setTotal} = props;
    const [listDataGroup, setListDataGroup] = useState([]);
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();


    const [isSpin, setIsSpin] = useState(false);
    const [disable, setDisable] = useState<any>(true);
    const [disableEdit, setDisableEdit] = useState<any>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [defaultValueOfSelect, setDefaultValueOfSelect] = useState({ value: '0', label: 'Select product group' });
    const blockInvalidChar = (e: any) => ['e', 'E', '+', '-', '.', '/',].includes(e.key) && e.preventDefault();
    const location = useLocation();
    const cancelResetValue = () => {
        form.resetFields();
        handleCancelSend();
    };
    const navigate = useNavigate()
    const getListData = async () => {
        setIsSpin(true)
        try {
            const res = await getListProductGroup()
            form.resetFields();
            const data = res.data.results?.map((item: any) => ({
                value: item.uuid,
                label: item.name
            }))
            if (productFilter.length > 0) {
                if (type == 'edit') {
                    formEdit.setFieldsValue(productFilter[0]);
                }else{
                    formEdit.setFieldsValue(defaultValueOfSelect);
                }
                const searchGroup = data.find((dt: any) => dt.value == productFilter[0].group_id);
                setDefaultValueOfSelect(searchGroup);
            }
            setIsSpin(false)
            setListDataGroup(data)
        } catch (error) {
            setIsSpin(false)
        }
    }

    useEffect(() => {
        getListData();
        onValueChange();
    }, [isModalAddProduct])

    const onFinish = async (values: any) => {
        setIsSpin(true)
        try {
                const values = { ...form.getFieldsValue(), group_id: form.getFieldValue('group_id')?.value, status: 'active' };
                try {
                    const res = await addProduct(values);
                    message.success(res.data.message)
                    setIsModalAddProduct(false);
                    if(location.search === ""){
                        setStatusOpenSearch(false);
                    }
                    form.resetFields();
                } catch (error) {
                    message.error('This article no / EAN code is already in use. Please choose a different article no/ EAN code.')
                    
                }
            const products = await getProductAll()
            setListProduct(products.data.results.data)
            setTotal(products.data.results.total);
            setIsSpin(false)
        } catch (error) {
            setIsSpin(false)
        }
        setIsModalAddProduct(false);
        form.resetFields();
    };

    const onFinishEdit = async (values: any) => {
        setIsSpin(true)
        try {
            if (productFilter.length > 0 ) {
                try {
                    const values = { ...formEdit.getFieldsValue(), group_id: !formEdit.getFieldValue('group_id')?.value ? productFilter[0].group_id : formEdit.getFieldValue('group_id')?.value, status: 'active' };
                    const res = await addProduct(values);
                    setIsModalAddProduct(false);
                    message.success(res.data.message);
                    navigate('/inventory-management/manage-product')
                    if(location.search === ""){
                        setStatusOpenSearch(false);
                    }
                    form.resetFields();

                } catch (error) {
                    message.error('The EAN code/Article no is duplicated. Please change to another.')
                }
            } 
            const products = await getProductAll()
            setListProduct(products.data.results.data)
            setTotal(products.data.results.total);
            setProductFilter([{ uuid: values?.uuid, article_no: values?.article_no, ean_code: values?.ean_code, producer: values?.producer, name: values?.name, group_id: values?.group_id }]);
            setIsSpin(false)
        } catch (error) {
            setIsSpin(false)
        }
        setIsModalAddProduct(false);
        form.resetFields();
    };

    const handleAddGroup = () => {
        setIsModalVisible(true)
    }

    const handleCancelSendGroup = () => {
        setIsModalVisible(false);
    };

    const handleOKSendGroup = () => {
        setIsModalVisible(false);
        getListData();
    };

    const onValueChange = () => {
        const values = form.getFieldsValue();
        if (values.article_no === '' || values.ean_code === '' || values.producer === '' ||
            values.name === '' || values.group_id === undefined || values.article_no === undefined ||
            values.ean_code === undefined || values.producer === undefined || values.name === undefined
        ) {
            setDisable(true);
            return;
        } else {
            setDisable(false);
            return;
        }
    };
    const onValueChangeEdit = () => {
        const values = formEdit.getFieldsValue();
        if (values.article_no === '' || values.ean_code === '' || values.producer === '' ||
            values.name === '' || values.group_id === undefined || values.article_no === undefined ||
            values.ean_code === undefined || values.producer === undefined || values.name === undefined
        ) {
            setDisableEdit(true);
            return;
        } else {
            setDisableEdit(false);
            return;
        }
    };


    return (
        <>
            <Modal
                visible={isModalAddProduct}
                width={"50%"}
                destroyOnClose={true}
                onCancel={cancelResetValue}
                onOk={() => {
                    handleOKSend();
                }}
                title={type === "add" ? "ADD NEW PRODUCT" : 'MODIFY PRODUCT'}
                className=""
                centered
                footer={null}
            >

                {
                    type == 'add'
                        ?
                        <Form onFinish={onFinish} autoComplete="off" form={form}>
                            <Form.Item name="uuid" label={<span className="w-[5rem] text-start"></span>} className="font-semibold hidden">
                                <Input onChange={onValueChange} onKeyDown={blockInvalidChar} />
                            </Form.Item>
                            <Form.Item name="article_no" label={<span className="w-[5rem] text-start">Article no</span>} className="font-semibold">
                                <Input onChange={onValueChange} type='number' onKeyDown={blockInvalidChar} />
                            </Form.Item>
                            <Form.Item name="ean_code" label={<span className="w-[5rem] text-start">EAN code</span>} className="font-semibold">
                                <Input onChange={onValueChange} type='number' onKeyDown={blockInvalidChar} />
                            </Form.Item>
                            <Form.Item name="producer" label={<span className="w-[5rem] text-start">Producer</span>} className="font-semibold">
                                <Input onChange={onValueChange} />
                            </Form.Item>
                            <Form.Item name="name" label={<span className="w-[5rem] text-start">Type</span>} className="font-semibold">
                                <Input onChange={onValueChange} />
                            </Form.Item>
                            <div className="flex items-center w-full ">
                                <Form.Item name="group_id" label="Product group " className="font-semibold  items-center w-full  ">
                                    <Select
                                        showSearch
                                        labelInValue
                                        defaultValue={type == 'edit' ?  productFilter[0]?.label : defaultValueOfSelect}
                                        style={{ minWidth: '100px' }}
                                        onChange={onValueChange}
                                        options={listDataGroup}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        className=" ml-2  pb-5 font-medium min-w-[7rem] text-xl  "
                                        style={{ backgroundColor: '#468da5', borderColor: '#468da5', color: '#fff', borderRadius: '4px', paddingLeft: '4px', height: '36px' }}
                                        // onClick={() => navigate('/inventory-management/manage-product-groups')}
                                        onClick={() => handleAddGroup()}
                                    >
                                        <div className='flex items px-10' >
                                            <i className='bx bx-plus text-2xl'></i>
                                            <span className=' ml-2  text-base font-semibold leading-5 ' >Add new product group</span>
                                        </div>
                                    </Button>
                                </Form.Item>
                            </div>
                            <Form.Item wrapperCol={{ offset: 32, span: 32 }} className="w-full rounded-lg" style={{ marginTop: '2rem' }}>
                                <Button
                                    className={`  pb-5 font-medium min-w-[6rem] text-xl rounded ${type == 'edit'|| !disable ? '!bg-button': '#ccc'} 
                                    ${type == 'edit'|| !disable ? '!border-button': 'border-[#ccc]'} !text-white !h-9 `}
                                    disabled={type == 'edit' ? false : disable}
                                    htmlType="submit"
                                >
                                    <div className='flex  px-4' >
                                        <i className='bx bx-save text-2xl'></i>
                                        <span className='ml-2 text-base font-semibold leading-5 ' >Save</span>
                                    </div>
                                </Button>
                            </Form.Item>
                        </Form>
                        :
                        <Spin size="large" spinning={isSpin} >
                            <Form onFinish={onFinishEdit} autoComplete="off" form={formEdit}>
                                <Form.Item name="uuid" label={<span className="w-[5rem] text-start"></span>} className="font-semibold hidden">
                                    <Input onChange={onValueChangeEdit} onKeyDown={blockInvalidChar} />
                                </Form.Item>
                                <Form.Item name="article_no" label={<span className="w-[5rem] text-start">Article no</span>} className="font-semibold">
                                    <Input onChange={onValueChangeEdit} type='number' onKeyDown={blockInvalidChar} />
                                </Form.Item>
                                <Form.Item name="ean_code" label={<span className="w-[5rem] text-start">EAN code</span>} className="font-semibold">
                                    <Input onChange={onValueChangeEdit} type='number' onKeyDown={blockInvalidChar} />
                                </Form.Item>
                                <Form.Item name="producer" label={<span className="w-[5rem] text-start">Producer</span>} className="font-semibold">
                                    <Input onChange={onValueChangeEdit} />
                                </Form.Item>
                                <Form.Item name="name" label={<span className="w-[5rem] text-start">Type</span>} className="font-semibold">
                                    <Input onChange={onValueChangeEdit} />
                                </Form.Item>
                                <div className="flex items-center w-full ">
                                    <Form.Item name="group_id" label="Product group " className="font-semibold  items-center w-full  ">
                                        <Select
                                            showSearch
                                            labelInValue
                                            defaultValue={type == 'edit' ? productFilter[0]?.group_id : defaultValueOfSelect}
                                            style={{ minWidth: '100px' }}
                                            onChange={onValueChangeEdit}
                                            options={listDataGroup}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            className=" ml-2  pb-5 font-medium min-w-[7rem] text-xl border !bg-button !border-button !text-white rounded !h-9 !pl-1 "
                                            // onClick={() => navigate('/inventory-management/manage-product-groups')}
                                            onClick={() => handleAddGroup()}
                                        >
                                            <div className='flex items px-10' >
                                                <i className='bx bx-plus text-2xl'></i>
                                                <span className=' ml-2  text-base font-semibold leading-5 ' >Add new product group</span>
                                            </div>
                                        </Button>
                                    </Form.Item>
                                </div>
                                <Form.Item wrapperCol={{ offset: 32, span: 32 }} className="w-full rounded !mt-8" >
                                    <Button
                                        className={`  pb-5 font-medium min-w-[6rem] text-xl !text-white border rounded ${disableEdit ? '!bg-gray': '!bg-button'} 
                                        ${disableEdit ? 'border-gray': 'border-button'}  !h-9`}
                                        disabled={disableEdit}
                                        htmlType="submit"
                                    >
                                        <div className='flex  px-4' >
                                            <i className='bx bx-save text-2xl'></i>
                                            <span className='ml-2 text-base font-semibold leading-5 ' >Save</span>
                                        </div>
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                }


            </Modal>
            <PopupProductGroup
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                handleCancelSend={handleCancelSendGroup}
                handleOKSend={handleOKSendGroup}
                setListDataGroup={setListDataGroup}
                type={0}
            />
        </>
    )
}

export default PopupProductAdd
