import React from 'react';
import { Outlet } from 'react-router-dom';
import SideMenu from '../../components/sideMenu/SideMenu';
import TopMenu from '../../components/topMenu/TopMenu';
const MainLayOut = () => {
	return (
		<div className="flex ">
			<div className=" bg-bodyMenu min-h-screen">
				<SideMenu />
			</div>
			<div
				className=" min-w-max w-full min-h-screen">
				<div className='fixed top-0 right-0 left-[220px] z-10'>
					<TopMenu />

				</div>
				<Outlet />
			</div>
		</div>
	);
};

export default MainLayOut;
