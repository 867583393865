import { Button, Input, message, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../pages/scss/style.scss'
import { getProductAll, getProductAllInventory, loadListProduct } from '../../service/auth/AuthService'
import { DataType } from '../../interface/home.interface';
import { ColumnsType } from 'antd/lib/table';
import PopupUpLoad from '../manage-product/PopupUpLoad'
const ProductData = () => {
    const navigate = useNavigate()
    const [filterSearch, setFilterSearch] = useState<string>('');
    const [productData, setProductData] = useState<any>([]);
    const [productFilter, setProductFilter] = useState<any>([])
    const [isOpen, setIsOpen] = useState(false)
    const [isSpin, setIsSpin] = useState(true);
    const [isModalUpLoad, setIsModalUpLoad] = useState(false);
    const [dataFile, setDataFile] = useState<any>()

    const handleAddProject = () => {
        navigate('/product-addition');
    }
    // sreach
    const handleClickSearch = async () => {
        setIsSpin(true);
        setIsOpen(true)
        try {
            const res = await getProductAllInventory(filterSearch);
            const results = res.data.results;
            const dataArray = [];
            dataArray.push({ article_no: results?.article_no, ean_code: results?.ean_code, producer: results?.producer, name: results?.name })
            setProductFilter(dataArray);
            setIsSpin(false);
        } catch (error) {
            setIsSpin(false);
        }
    }

    // search


    const getListData = async () => {
        try {
            const res = await getProductAll(1, 999999);
            setIsSpin(false);
            let data = res.data?.results.data;
            data = [...data].reverse();
            setProductData(data)
        } catch (error) {
            setIsSpin(false);
        }
    }
    useEffect(() => {
        getListData()
    }, [])
    const handleDowload = async () => {
        fetch('https://warehudev-api.maxline.hu/api/download-sample-file')
            .then(response => {
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.click();
                });
                //window.location.href = response.url;
            });
    }
    // modal up load file
    const handleShowUpLoad = async () => {

        setIsModalUpLoad(true)
    }
    const handleCancelUpLoad = () => {
        setIsModalUpLoad(false)
    }
    const handleOkUpLoad = async () => {
        const res = await loadListProduct({ csv_file: dataFile[0] });
        message.success(res.data.message);
        setIsModalUpLoad(false)
    }

    const columns: ColumnsType<DataType> = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            className: "text-center font-semibold ",
            align: 'center',
            render: (text, object, index) => index + 1,
        },
        {
            title: 'Article no',
            dataIndex: 'article_no',
            key: 'article_no',
            className: "text-center ",
            align: 'center',
        },
        {
            title: 'EAN code',
            dataIndex: 'ean_code',
            key: 'ean_code',
            className: "text-center ",
            align: 'center',
        },
        {
            title: 'Producer',
            dataIndex: 'producer',
            key: 'producer',
            className: "text-center ",
            align: 'center',
        },
        {
            title: 'Type',
            dataIndex: 'name',
            key: 'name',
            className: "text-center ",
            align: 'center',
        },

    ];
    const dataSource = [
        {
            key: '1',
            storage_place: 'A1-02-04',
            operation: 'Removal',
            modification: '2020.01.27.17:14:33',
        },
        {
            key: '2',
            storage_place: 'A1-02-05',
            operation: 'Storage',
            modification: '2020.01.30.11:28:10',
        },

    ];

    const columnsInventory = [
        {
            title: 'Storage place',
            dataIndex: 'storage_place',
            key: 'storage_place',
            className: "text-center font-semibold "
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            className: "text-center "
        },
        {
            title: 'Last modification ',
            dataIndex: 'modification',
            key: 'modification',
            className: "text-center "
        },
        {
            title: 'Remark ',
            dataIndex: 'remark',
            key: 'remark',
            className: "text-center "
        },

    ];

    return (
        <>
            {isSpin ?
                <div className='w-full h-full flex justify-center items-center'>
                    <Spin spinning={isSpin} size="small" />

                </div>
                :
                <div className='px-8 2xl:px-6 pt-8'>
                    <h1 className='text-3xl mb-5'>Inventory management</h1>
                    <div className=' w-[80%] 2xl:w-full flex  flex-col flex-start mx-auto'>
                        <div className='flex items-center mb-10 justify-between w-[80%]'>
                            <h1 className=' text-2xl w-[15rem]'>Product search</h1>
                            <div className=' w-[70%]  mx-4 py-2'>
                                <Input className='h-[36px]' onChange={(e) => setFilterSearch(e.target.value)} />
                            </div>
                            <Button
                                className="  pb-5 font-medium min-w-[8rem] text-xl !bg-button border !border-button !h-9 !text-white "
                                onClick={handleClickSearch}
                            >
                                <div className='flex ' >
                                    <i className='bx bx-search text-xl'></i>
                                    <span className='ml-2 text-base font-semibold leading-5 ' >Search</span>
                                </div>
                            </Button>
                        </div>
                        <div className='mb-10 flex  w-[60%] 2xl:w-full '>
                            <Button
                                className="  pb-5 mr-2 font-medium  text-xl rounded !bg-button border !border-button !h-9 !text-white  "
                                onClick={handleAddProject}
                            >
                                <div className='flex items px-4' >
                                    <i className='bx bx-plus text-xl'></i>
                                    <span className='ml-2  text-base font-semibold leading-5 ' >Add product</span>
                                </div>
                            </Button>
                            <Button
                                className="  pb-5 mx-2 font-medium text-xl rounded !bg-button border !border-button !h-9 !text-white "
                                onClick={handleShowUpLoad}
                            >
                                <div className='flex items px-4' >
                                    <i className='bx bx-upload text-2xl'></i>
                                    <span className='ml-2 text-base font-semibold leading-5 ' >Load product list</span>
                                </div>
                            </Button>
                            <Button
                                className="  pb-5  ml-2 font-medium text-xl rounded !bg-button border !border-button !h-9 !text-white"
                                onClick={handleDowload}
                            >
                                <div className='flex items px-4' >
                                    <i className='bx bx-download text-2xl'></i>
                                    <span className='ml-2 text-base font-semibold leading-5 ' >Download sample file</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div className='flex mb-10 justify-end '>
                        <div className='w-[80%] 2xl:w-full mx-auto  '>


                            <div className='w-[80%] '>

                                <h1 className='text-xl mb-4'>Product data</h1>

                                <div className=' mx-auto  border-black '>
                                    <Table dataSource={productData} columns={columns} bordered
                                        pagination={{ pageSizeOptions: ["10", "20", "30"], showSizeChanger: true }}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>





                </div>

            }
            <PopupUpLoad
                handleOkUpLoad={handleOkUpLoad}
                handleCancelUpLoad={handleCancelUpLoad}
                isModalUpLoad={isModalUpLoad}
                setDataFile={setDataFile}

            />

        </>
    )
}

export default ProductData
