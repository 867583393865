import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Select, Spin } from 'antd';
import Barcode from 'react-barcode';
import { profileLogin, getPlan } from '../../service/auth/AuthService';
import { sizePaper } from './data';
const StorageLabelPrinting = () => {
	const [nameWH, setNameWH] = useState('')
	const [currentPage, setCurrentPage] = useState('A4')
	const [dataArray, setDataArray] = useState<any>([]);
	// const [dataSelect, setDataSelect] = useState<any>()
	const [isSpin, setIsSpin] = useState(true);
	const { Option } = Select;
	const getListData = async () => {
		setIsSpin(true);
		try {
			const user = await profileLogin()
			const lastData = user.data?.results?.warehu
			if (lastData) {
				setNameWH(lastData?.name)
				const dataView = await getPlan(lastData?.uuid)
				// setDataSelect(dataView.data.results[0]?.list_row_by_zone[0])
				setIsSpin(false)
				let zones: any[] = []
				dataView.data.results[0]?.list_row_by_zone.forEach((item: any, index: number) => {
					item.des.forEach((des: any) => {
						des.forEach((_des: any) => {
							zones = [...zones, _des]
						})
					})
				})
				setDataArray(zones)
			}
			setIsSpin(false)
		} catch (error) {
			setIsSpin(false)
		}
	}
	const handleChangeSelect = (e: any) => {
		setCurrentPage(e)
	};
	useEffect(() => {
		getListData()
	}, []);

	return (
		<>
			{isSpin ?
				<div className='w-full h-full flex items-center justify-center'>
					<Spin size="large" spinning={isSpin} />

				</div>
				:
				<div className="px-4 pt-[4rem]">
					<h1 className='text-2xl mb-[1.5rem]'>Storage label design and printing</h1>
					<div className='flex xl:flex-col'>
						<div className='w-1/3 xl:w-full p-4'>
							<div className="flex justify-between items-center mb-5">
								<h1>Page size</h1>
								<Select
									// labelInValue
									defaultValue={'A4'}
									style={{ width: '50%' }}
									onChange={e => {
										handleChangeSelect(e);
									}}

								>
									{sizePaper.map((item: any, index: number) => {
										return (
											<Option key={index} value={item.value}>
												{item.title}
											</Option>
										)
									})}
								</Select>

							</div>
							<div className="flex justify-between items-center mb-5 ">
								<h1>Label size</h1>
								<Select
									labelInValue
									defaultValue={{ value: 'big', label: '21 cm x 7 cm (7 pc / A4 page)' }}
									style={{ width: '70%' }}
									// onChange={handleChange}
									options={[
										{ value: 'big', label: '21 cm x 7 cm (7 pc / A4 page)', },
										{ value: 'small', label: '5 cm x 4 cm (28 pc / A4 page)', },
									]}
								/>
							</div>
							<div className='flex justify-between items-center mb-5'>
								<h1>Print all the storage labels</h1>
								<Checkbox ></Checkbox>

							</div>
							<div className="flex justify-between items-center mb-5 ">
								<h1>Select row</h1>
								<Select
									labelInValue
									defaultValue={{ value: '1', label: '1' }}
									style={{ width: '50%' }}
									// onChange={handleChange}
									options={[
										{ value: '1', label: '1', },
										{ value: '2', label: '2', },
									]}
								/>
							</div>
							<div className="flex justify-between items-center mb-5 ">
								<h1>Select column</h1>
								<Select
									labelInValue
									defaultValue={{ value: '1', label: '1' }}
									style={{ width: '50%' }}
									// onChange={handleChange}
									options={[
										{ value: '1', label: '1', },
										{ value: '2', label: '2', },
									]}
								/>
							</div>
							<div className="flex justify-between items-center mb-5 ">
								<h1>Storage code</h1>
								<div className='w-[70%]'>
									<Input placeholder='Storage name' />

								</div>
							</div>
							<div className='mt-20 xl:mt-8 flex justify-center'>
								<div className="flex items-center justify-center px-4 py-4 bg-gray w-[10rem]">
									<span className='mr-5'>Print</span>
									<div className='p-1 border-black border-[1px] rounded-full ' style={{ borderRadius: '100%' }}>
										<i className='bx bxs-printer text-4xl'></i>
									</div>
								</div>

							</div>
						</div>
						{dataArray.length > 0 ?
							<div className='w-2/3 xl:w-full xl:mt-8'>
								<div className="px-2 flex justify-center">
									<div className='px-2 py-4 border-black border-[1px] grid gap-4 grid-cols-5 2xl:gap-1 2xl:grid-cols-4  '>
										{dataArray.map((item: any, index: number) => {
											return (
												<div key={index} className=" mb-2 ">
													<div key={index} className=" border-gray border-[1px]  text-xl ">
														<Barcode format="CODE128" width={1.2} height={70} value={item.name} />
													</div>
												</div>
											)
										})}
									</div>
								</div>
							</div>
							:
							<div className='w-2/3 xl:w-full xl:mt-8'>
								<div className=' flex  w-full h-full '>
									<div className='px-2 py-4 border-black border-[1px] w-full text-xl justify-center items-center text-center'>No data</div>
								</div>
							</div>
						}
					</div>
				</div>
			}


		</>
	)
};

export default StorageLabelPrinting;
