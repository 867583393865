import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Form, message, Select, Spin, Table, Pagination, Space, InputNumber } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons';
import { productPicking, profileLogin, loadListProduct, getProductAll, delelteProduct, addProductPicking, delPicking, delProductPicking } from '../../service/auth/AuthService';
import { AlignType } from 'rc-table/lib/interface'
import type { PaginationProps } from 'antd';
import PopupDeletePicking from '../picking/PopupDeletePicking';
import PopupDeletePickingProduct from '../picking/PopupDeletePickingProduct';
import { getAccessToken } from '../../helper/tokenHelper';
import { TypeProductList } from '../../interface/picking.interface';

const TransferList = () => {

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
	const [isModalVisibleDeletePickingProduct, setIsModalVisibleDeletePickingProduct] = useState(false);
	const [dataItemDelete, setDataItemDelete] = useState({ uuid: "", picking_uuid: "" });
	const [isModalUpLoad, setIsModalUpLoad] = useState(false);
	const [isModalAddProduct, setIsModalAddProduct] = useState(false);
	const [isCreated, setIsCreated] = useState(false);
	const [listPicking, setListPicking] = useState([{}]);
	const [dataFile, setDataFile] = useState<any>()
	const [productFilter, setProductFilter] = useState<any>([])
	const [isSpin, setIsSpin] = useState(false);
	const navigate = useNavigate()
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [type, setType] = useState("add");
	const [form] = Form.useForm();
	const [articleProducts, setArticleProducts] = useState<any>([])
	const [eanProducts, setEanProducts] = useState<any>([])
	const [totalStoredProduct, setTotalStoredProduct] = useState<number>(0);
	const [productData, setProductData] = useState<any>({})
	const [proDataOpen, setProDataOpen] = useState(false)
	const [productsSelectInit, setProductsSelectInit] = useState([
		{ article_no: '', ean_code: '', quantity: 1, product_id: "" },
		{ article_no: '', ean_code: '', quantity: 1, product_id: "" },
		{ article_no: '', ean_code: '', quantity: 1, product_id: "" },
	])
	const [idProduct, setIdProduct] = useState<any>();
	const [pickingUUID, setPickingUUID] = useState<string>('');
	// const [listPickingProducts, setListPickingProducts] = useState([]);

	const getListData = async () => {
		setIsSpin(true);
		try {
			//Get all product 
			const resAllProduct = await getProductAll(1, 99999999999,);
			const articleProducts = resAllProduct.data.results?.data.map((item: any) => ({
				value: item.uuid,
				label: item.article_no
			}))
			setArticleProducts(articleProducts)
			const eanProducts = resAllProduct.data.results?.data.map((item: any) => ({
				value: item.uuid,
				label: item.ean_code
			}))
			setEanProducts(eanProducts);
			setIsSpin(false);
		} catch (error) { setIsSpin(false); }
		
	}
	const getListProductPicking = async () => {
		setIsSpin(true);
		try {
			const res = await productPicking()
			const data = res.data.results.data;
			const { total, current_page, last_page } = res.data.results;
			setListPicking(data.filter((item: any) => item?.status === "Finished"));
			setTotal(data.filter((item: any) => item?.status === "Finished")?.length);
			setCurrentPage(current_page);
			setIsSpin(false);
		} catch (error) {
			setIsSpin(false);
		}

	}


	useEffect(() => {
		getListData();
		getListProductPicking()
		// getTotalStoreProduct();
	}, [])

	useEffect(() => {
		if (pickingUUID) {
			const item = listPicking.find((e: any) => e.uuid == pickingUUID);
			setProductData(item)
		}
	}, [listPicking])



	const onFinish = async (value: any, options: any = {}) => {
		setIsSpin(true);
		const result = productsSelectInit.reduce((acc: any, curr: any) => {
			const objInAcc = acc.find((o: any) => o.product_id === curr.product_id);
			if (objInAcc) objInAcc.quantity += curr.quantity;
			else acc.push(curr);
			return acc;
		}, []);
		const values = { "uuid": "", "products": result };
		try {
			await addProductPicking(values);
			getListData();
			form.setFieldsValue({
				products: [
					{ article_no: '', ean_code: '', quantity: 1, product_id: "" },
					{ article_no: '', ean_code: '', quantity: 1, product_id: "" },
					{ article_no: '', ean_code: '', quantity: 1, product_id: "" },
				]
			});
			setIsSpin(false);
			// form.resetFields();
		} catch (error) { setIsSpin(false); }

	}
	const handleAddProduct = () => {
		setType("add");
		setIsModalAddProduct(true)
	}

	const handleCancelSend = () => {
		setIsModalVisible(false);
		setIsModalAddProduct(false)
	};
	const handleOKSend = (values: any) => {
		setIsModalVisible(false);
	};
	// modal up load file
	const handleShowUpLoad = async () => {
		setIsModalUpLoad(true)
	}
	const handleCancelUpLoad = () => {
		setIsModalUpLoad(false)
	}
	const handleOkUpLoad = async () => {
		const res = await loadListProduct({ csv_file: dataFile[0] });
		message.success(res.data.message);
		setIsModalUpLoad(false)
	}
	const handleChangeFormItem = async (product_id: string, index: number) => {
		const ean_code = eanProducts.find((e: any) => e.value == product_id);
		const article_no = articleProducts.find((e: any) => e.value == product_id);
		productsSelectInit[index].article_no = article_no.label;
		productsSelectInit[index].ean_code = ean_code.label;
		productsSelectInit[index].product_id = product_id;
		setProductsSelectInit(productsSelectInit);
		const products = { products: productsSelectInit };
		form.setFieldsValue(products);
	}
	const handleChangeQuantity = async (quantity: any, index: number) => {
		productsSelectInit[index].quantity = quantity;
		setProductsSelectInit(productsSelectInit);
		const products = { products: productsSelectInit };
		form.setFieldsValue(products);
	}

	const removeRow = async (index: number) => {
		productsSelectInit.splice(index, 1)
		const products = { products: productsSelectInit };
		form.setFieldsValue(products);
	}

	const addRow = async () => {
		productsSelectInit.push({ article_no: '', ean_code: '', quantity: 0, product_id: "" });
		const products = { products: productsSelectInit };
		form.setFieldsValue(products);
	}

	const handleCancelDelete = () => {
		setIsModalVisibleDelete(false);
		setIsModalVisibleDeletePickingProduct(false);
	};

	const handleOKDelete = async () => {
		setIsModalVisibleDelete(false);
		setIsSpin(true);
		try {
			await delPicking(dataItemDelete.uuid);
			await getListProductPicking();
			setProDataOpen(false);
			setIsSpin(false);
		}
		catch (error) {
			setIsSpin(false);
		}

	}
	const handleOKDeletePickingProduct = async () => {

		// Delete picking product
		setIsModalVisibleDeletePickingProduct(false);
		setIsSpin(true);
		try {
			await delProductPicking(dataItemDelete.uuid);
			await getListProductPicking();
			setIsSpin(false);
		}
		catch (error) { setIsSpin(false); }

	}

	const handleClickProductList = (item: any) => {

		if (item.uuid === idProduct) {
			setProDataOpen(!proDataOpen)
		} else {
			setProDataOpen(true)
		}
		setIdProduct(item?.uuid)
		setProductData(item)
		setPickingUUID(item.uuid);

	}
	const handleClickDeletePicking = (item: any) => {
		setIsModalVisibleDelete(true);
		setDataItemDelete(item);
	}

	const handleClickDeletePickingProduct = (item: any) => {
		setIsModalVisibleDeletePickingProduct(true);
		setDataItemDelete(item);
	}
	// download
	const handleDowload = async () => {
		fetch('https://warehudev-api.maxline.hu/api/download-sample-file-picking',
			{
				headers:
					new Headers({
						'Authorization': 'Bearer ' + getAccessToken(),
						'Content-Type': 'application/x-www-form-urlencoded'
					})
			})
			.then(response => {
				response.blob().then(blob => {
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.click();
				});
				//window.location.href = response.url;
			});
	}

	const columnsProduct = [
		{
			title: '#',
			dataIndex: 'key',
			key: 'key',
			align: 'center' as AlignType,

		},
		{
			title: 'Code of picking',
			dataIndex: 'code',
			key: 'code',
			align: 'center' as AlignType,

		},
		{
			title: 'Date of creation',
			dataIndex: 'created_at',
			key: 'created_at',
			align: 'center' as AlignType,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			align: 'center' as AlignType,
			render: (article_no: string, item: any) => {
				return (
					<div className={`${item.status === "Finished" ? 'text-green font-semibold' : ''}`}>{item.status}</div>
				)
			}
		},
		{
			title: 'Product list',
			dataIndex: 'product_list',
			key: 'border-top: 1px solid #000;',
			align: 'center' as AlignType,
			render: (article_no: string, item: any) => {
				return (
					<Button
						className="  pb-5 font-medium mr-2 text-2xl rounded !bg-button border !border-button !h-9 !text-white  "
						onClick={() => handleClickProductList(item)}
					>
						<div className='flex items-center' >
							<i className='bx bx-list-ul text-2xl'></i>
							<span className='ml-2 text-base font-semibold ' >Product list</span>
						</div>
					</Button>
				);
			}
		},
		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			align: 'center' as AlignType,
			render: (article_no: string, item: any) => {
				return (
					<Button
						className="  pb-5 font-medium mr-2 text-xl rounded !bg-button border !border-button !h-9 !text-white  "
						onClick={() => handleClickDeletePicking(item)}
					>
						<div className='flex items-center' >
							<i className='bx bx-trash text-xl'></i>
							<span className='ml-2 text-base font-semibold' >Delete</span>
						</div>

					</Button>
				);
			}
		},
		{
			title: 'Delivery note',
			dataIndex: 'delivery_note',
			key: 'delivery_note',
			align: 'center' as AlignType,
			render: (article_no: string, item: any) => {
				return (
					<Button
						className="  pb-5 font-medium mr-2 text-xl rounded !bg-button border !border-button !h-9 !text-white  "
					>
						<div className='flex items-center' >
							<i className='bx bx-printer text-xl'></i>
							<span className='ml-2 text-base font-semibold' >Print</span>
						</div>

					</Button>
				);
			}
		},
	];

	const columnsProductTransfer = [
		{
			title: 'Article no.',
			dataIndex: ["product", "article_no"],
			key: 'article_no',
			align: 'center' as AlignType,
			className: 'font-semibold',


		},
		{
			title: 'EAN code',
			dataIndex: ["product", "ean_code"],
			key: 'ean_code',
			align: 'center' as AlignType,
			className: 'font-semibold',
		},
		{
			title: 'Product name',
			dataIndex: ["product", "name"],
			key: 'product_name',
			align: 'center' as AlignType,
			className: 'font-semibold',
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			align: 'center' as AlignType,
			className: 'font-semibold',
			render: (_: any, item: any) => {
				return (<>
					<div className=' '>
						<span className="border-[1px] border-[#888] p-3">{item?.quantity}</span></div>
				</>)
			}

		},
		{
			title: 'Storage list',
			dataIndex: 'storage_list',
			key: 'storage_list',
			align: 'center' as AlignType,
			render: (id: string, item: any) => {
				return (
					<>
						{item.listOfStorage.map((item: any, index: number) => {
							return (
								<div key={index}>
									<p className='m-0' >{item.storage_place?.name}</p>
								</div>
							)

						})}
					</>

				);
			}
		},

		{
			title: 'Delete',
			dataIndex: 'delete',
			key: 'delete',
			align: 'center' as AlignType,
			render: (article_no: string, item: any) => {
				return (
					<Button
						className="  pb-5 font-medium mr-2 text-xl rounded !bg-button border !border-button !h-9 !text-white  "


						onClick={() => handleClickDeletePickingProduct(item)}
					>
						<div className='flex' >
							<i className='bx bx-trash text-xl'></i>
							<span className='ml-2 text-base font-semibold' >Delete</span>
						</div>

					</Button>
				);
			}
		},
	];


	return (
		<>
			{isSpin ?
				<div className='w-full h-full flex justify-center items-center'>
					<Spin size="large" spinning={isSpin} />

				</div>
				:
				<div className='px-4 pt-[4rem]'>
					<h1 className='text-2xl mb-5'>Transfer List</h1>
					<div className='px-10 w-[65%] 2xl:w-full'>
						{
							isCreated ?
								<>
									<Form
										name="dynamic_form_nest_item"
										onFinish={onFinish}
										style={{ width: "100%" }}
										autoComplete="off"
										form={form}
									>
										<Form.List
											name="products"
											initialValue={productsSelectInit}
										>
											{(fields, { add, remove }) => (
												<>
													{fields.map(({ key, name, ...restField }, index) => (
														<div key={key}>
															<Space key={key} style={{ display: 'flex', }} align="baseline">
																<h1 className="w-[1.5rem]">{index + 1} .</h1>
																<Form.Item
																	{...restField}
																	label="Article no:"
																	name={[name, 'article_no']}
																	rules={[{ required: true, message: 'Missing article no' }]}
																>
																	<Select
																		labelInValue
																		showSearch
																		placeholder="Article no"
																		defaultValue={{ value: '0', label: 'Select article no' }}
																		style={{ minWidth: '200px' }}
																		onChange={e => handleChangeFormItem(e.value, index)}
																		options={articleProducts}
																	/>
																</Form.Item>
																<Form.Item
																	{...restField}
																	label="EAN code:"
																	name={[name, 'ean_code']}
																	rules={[{ required: true, message: 'Missing ean code' }]}
																>
																	<Select
																		labelInValue
																		showSearch
																		placeholder="Article no"
																		defaultValue={{ value: '0', label: 'Select ean no' }}
																		style={{ minWidth: '200px' }}
																		onChange={e => handleChangeFormItem(e.value, index)}
																		options={eanProducts}
																	/>
																</Form.Item>
																<Form.Item
																	{...restField}
																	label="Quantity:"
																	name={[name, 'quantity']}
																	rules={[{ required: true, message: 'Missing quantity' }]}
																>
																	<InputNumber min={1} max={1000000000000000000} defaultValue={1} onChange={e => handleChangeQuantity(e, index)} />
																</Form.Item>
																<MinusCircleOutlined onClick={() => removeRow(index)} />
															</Space>
														</div>
													))}
													<div className='flex justify-between w-[49%]'>
														<Form.Item>
															<Button className="  pb-5 font-medium mr-2  text-xl rounded !bg-button border !border-button !h-9 !text-white "
																onClick={() => addRow()} >
																<div className='flex items px-4 w-[250px] justify-center' >
																	<i className='bx bx-plus text-xl'></i>
																	<span className='ml-2  text-base font-semibold leading-5 ' >Add product</span>
																</div>
															</Button>
														</Form.Item>
														<Form.Item>
															<Button className=" pb-5 font-medium mx-2  text-xl rounded !bg-button border !border-button !h-9 !text-white"
																htmlType="submit">
																<div className='flex items px-4' >
																	<i className='bx bx-save text-xl'></i>
																	<span className='ml-2  text-base font-semibold leading-5 ' >Save</span>
																</div>
															</Button>
														</Form.Item>
													</div>
												</>
											)}
										</Form.List>
									</Form>
								</>
								:
								<>
								</>
						}
						<Spin size="large" spinning={isSpin}>
							<div className="mb-10  ">
								<div className=''>
									<Table className=''
										dataSource={listPicking} columns={columnsProduct} bordered
										pagination={{ pageSizeOptions: ["10", "20", "30"], showSizeChanger: true }}
									/>
								</div>
							</div>
						</Spin>
					</div>
					<div className="px-10 w-[65%] 2xl:w-full">
						{proDataOpen ?
							<div>
								<h1 className="text-2xl mb-6">Product data</h1>
								<p className='text-base font-medium'> Code of picking: <span className='font-normal'>{productData.code}</span></p>
								<p className='text-base font-medium'> Date of creation: <span className='font-normal'>{productData.created_at}</span></p>
								<p className='text-base font-medium'> Status:
									<span className={`ml-1 font-semibold ${productData.status === 'Deleted' ? 'text-red'
										:
										productData.status === 'Finished' ? 'text-green' : ''}`}>
										{productData.status === "Finished" ? "Completed" : productData.status}
									</span>
								</p>
								<div className="mb-10  ">
									<div className='border-t-[1px] border-black border-l-[1px]'>
										<Table dataSource={productData?.product_pickings} columns={columnsProductTransfer} pagination={false} />
									</div>
								</div>
								<div className='flex justify-between'>
									<Form.Item>
										<Button className="  pb-5 font-medium  text-xl rounded !bg-button border !border-button !h-9 !text-white  "
										>
											<div className='flex items px-4' >
												<i className='bx bx-plus text-xl'></i>
												<span className='ml-2  text-base font-semibold leading-5 ' >Add product</span>
											</div>
										</Button>
									</Form.Item>
									<Form.Item>
										<Button className=" pb-5 font-medium  text-xl rounded !bg-button border !border-button !h-9 !text-white"
											htmlType="submit">
											<div className='flex items px-4' >
												<i className='bx bx-save text-xl'></i>
												<span className='ml-2  text-base font-semibold leading-5 ' >Save</span>
											</div>
										</Button>
									</Form.Item>
								</div>
							</div>
							:
							<div></div>
						}
					</div>

					<PopupDeletePicking
						handleCancelDelete={handleCancelDelete}
						handleOKDelete={handleOKDelete}
						isModalVisibleDelete={isModalVisibleDelete}
						dataItem={dataItemDelete}
						title="DELETE TRANSFER"
					/>
					<PopupDeletePickingProduct
						handleCancelDelete={handleCancelDelete}
						handleOKDelete={handleOKDeletePickingProduct}
						isModalVisibleDelete={isModalVisibleDeletePickingProduct}
						dataItem={dataItemDelete}
						title="DELETE TRANSFER PRODUCT"
					/>
				</div >
			}
		</>
	)
}

export default TransferList



