
export const MessageConstantError = {
	emailHasNone: 'Please input your email!',
	emailNoneFormat: 'Email not correct format!',
};
export const MessageConstantSuccess = {
	loginSuccess: 'Đăng nhập thành công !',
	changePasswordSuccess: 'Bạn đã đổi mật khẩu thành công',
	logoutSuccess: 'Bạn đã đăng xuất thành công',
	updateStatusSuccess: 'Bạn đã cập nhật trạng thái thành công',
	createUserSuccess: 'Tạo mới tài khoản người dùng thành công',
	updateUserSuccess: 'Cập nhật tài khoản người dùng thành công',
};

export const Format_Email = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const Social_Login = [
	{
		title: 'facebook',
		content: 'Sign in with Facebook',
	},
	{
		title: 'google',
		content: 'Sign in with Google',
	},
];

export const Message_Error_System = 'System has error, you need to check again!';
