import React, { useState, useCallback, useEffect } from 'react';
import { Button, Checkbox, Form, Input, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { loginUser, socialLoginUser } from '../../service/auth/AuthService';
import { useDispatch,  } from 'react-redux';
import { userAction } from '../../reducer/userReducer';
import { Message_Error_System } from '../../constant/auth.constant';
import {
	setAccessToken,
	deleteRememberMe,
	deleteUserAndPasswordLocal,
	getRememberMe,
	getUserAndPassword,
	setUserAndPasswordLocal,
	setRememberMe,
} from '../../helper/tokenHelper';
import { Format_Email } from '../../constant/auth.constant';
import { errorAuth } from '../../enum/auth.error';
import { configApp, firebaseConfig } from '../../config/config';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { getToken } from '../../helper/tokenHelper';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import 'firebase/auth';
import { initializeApp } from 'firebase/app';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


import './Authenticate.scss';
import { TypeLogin } from '../../interface/manage.interface';

export default function Authenticate() {
	const rememberMe = getRememberMe() === 'true';
	const dispatch = useDispatch();
	const [isSpin, setIsSpin] = useState(false);
	const navigate = useNavigate();
	const provider = new GoogleAuthProvider();
	const [formLogin] = useForm();
	const infoLocal = getUserAndPassword();

	useEffect(() => {
		if (getToken(configApp.tokenKey)) {
			navigate('/home')
		}
		if (rememberMe) {
			formLogin.setFieldsValue({
				email: infoLocal.email,
				password: infoLocal.password,
				rememberMe,
			});
		} else {
			deleteRememberMe();
			deleteUserAndPasswordLocal();
		}
	}, []);

	const onFinish = (values: TypeLogin) => {
		const params = {
			email: values.email,
			password: values.password,
		};

		setIsSpin(true);
		loginUser(params)
			.then((res: any) => {

				dispatch(userAction.setUserLogin(res.data.results));
				message.success(res.data.message);
				setAccessToken(res.data.results.token);
				setIsSpin(false);
				if (values.rememberMe) {
					// set username and password to local storage
					setRememberMe('true');
					setUserAndPasswordLocal(params);
				} else {
					deleteRememberMe();
					deleteUserAndPasswordLocal();
				}
				navigate('/home');
			})
			.catch((err: any) => {
				message.error(err.response.data.message);
				setIsSpin(false);
			});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const onLoginStart = useCallback(() => {
		signInWithPopup(auth, provider)
			.then(result => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential!.accessToken;
				// The signed-in user info.
				const userLoginSocial: any = result.user;
				// ...

				const paramsLoginGg = {
					name: userLoginSocial.displayName,
					email: userLoginSocial.email,
					auth_type: 'google',
					social_id: userLoginSocial.providerData[0].uid,
					social_token: token,
				};

				setIsSpin(true);
				socialLoginUser(paramsLoginGg)
					.then(res => {
						dispatch(userAction.setUserLogin(res.data.results));
						setAccessToken(res.data.results.token);
						setIsSpin(false);
						message.success(res.data.message);
						navigate('/home');
					})
					.catch(err => {
						message.error(err.response.data.message);
						setIsSpin(false);
					});
			})
			.catch(error => {
				console.log(error);
				// Handle Errors here.
				message.error(Message_Error_System);
			});
	}, []);
	return (
		<Spin size="small" spinning={isSpin}  >
			<div className="bg_auth h-[100vh] flex items-center ">
				<div className="item_auth w-1/2 flex items-center m-auto h-[90%] rounded-lg overflow-hidden box shadow-lg shadow-gray-400 md:w-3/4 sm:w-3/4 sm:h-[80%] ">
					<div className="bg_left w-1/2 h-full lg:hidden"></div>
					<div className="w-1/2 h-full lg:w-full">
						<Form
							form={formLogin}
							name="basic"
							labelCol={{ span: 32 }}
							wrapperCol={{ span: 32 }}
							initialValues={{ remember: true }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
							layout="vertical"
							size="large"
							className=" py-5 px-10 w-full h-full bg-white rounded my-auto flex items-center justify-center lg:flex lg:justify-center"
						>
							<div className="py-auto px-10">
								<h1 className="mb-[1rem] text-2xl font-sans text-center sm:text-xl">Hello Again!</h1>
								<p className="text-gray-400 text-center sm:text-xs ">
									Welcome to Warehu login page, have a nice day!
								</p>
								<Form.Item
									className="rounded-md item-form-input mb-[1rem]"
									name="email"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(new Error(errorAuth.EMAIL_NONE));
												} else if (!Format_Email.test(val)) {
													return Promise.reject(new Error(errorAuth.EMAIL_FORMAT));
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input prefix={<UserOutlined className="mr-2" />} placeholder="Email" />
								</Form.Item>

								<Form.Item
									className="rounded-md item-form-input mb-[1rem]"
									name="password"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(new Error(errorAuth.PASSWORD_NONE));
												} else if (val.length < 6) {
													return Promise.reject(new Error(errorAuth.PASSWORD_LENGTH));
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input.Password prefix={<LockOutlined className="mr-2" />} placeholder="Password" />
								</Form.Item>
								<div className="flex justify-between items-center mb-2 ">
									<Form.Item
										className="item-check "
										name="rememberMe"
										valuePropName="checked"
										wrapperCol={{ span: 32 }}
									>
										<Checkbox>
											<p className="mb-0 sm:text-sm font-semibold">Remember me</p>
										</Checkbox>
									</Form.Item>
									<p
										className=" text-[#361BD3] font-semibold text-right cursor-pointer mb-0 sm:text-sm"
										onClick={() => {
											navigate('/forgot-password');
										}}
									>
										Forgot Password?
									</p>
								</div>

								<Form.Item wrapperCol={{ offset: 32, span: 32 }} className="w-full rounded-lg ">
									<Button type="primary" htmlType="submit" className="w-full rounded-lg ">
										<p className="m-auto sm:text-sm">Login</p>
									</Button>
								</Form.Item>

								<FacebookLoginButton className="login-button" />
								<GoogleLoginButton className="login-button" onClick={onLoginStart} />

								<div className="flex justify-center">
									<p className="font-sans font-semibold sm:hidden "> You have an account? </p>
									<p
										className="font-sans underline ml-1 cursor-pointer text-[#361BD3] font-semibold"
										onClick={() => {
											navigate('/register');
										}}
									>
										SIGN UP
									</p>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</Spin>
	);
}
