import React from 'react'
import { Modal, Spin } from 'antd';
const PopupDelete = (props: any) => {
    const { isModalVisibleDelete, handleCancelDelete, handleOKDelete, dataItem, isSpin, title } = props;
    return (
        <div>

            <Modal
                destroyOnClose={true}
                visible={isModalVisibleDelete}
                onCancel={handleCancelDelete}
                onOk={handleOKDelete}
                className="round-sm text-center "
                centered
                title={title == 'group-product' ? "DELETE PRODUCT GROUP" : 'DELETE PRODUCT'}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { minWidth: '7rem', backgroundColor: '#468da5', borderColor: '#468da5', fontWeight: '500' } }}

            >
                <Spin size="large" spinning={isSpin} >
                    <div className="flex flex-col justify-center">
                        <div className="mb-2 text-base ">
                            <p className="text-red"> {`* Do you want delete product ${title == 'group-product' ? 'group' : ''}  name: ${title == 'group-product' ? dataItem?.name : dataItem[0]?.producer} !!!`}</p>
                        </div>
                    </div>
                </Spin>
            </Modal>


        </div>
    );

}

export default PopupDelete
