import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Input, message, Spin, Table,  } from 'antd'
import PopupProductAdd from './PopupProductAdd';
import { profileLogin, getProductAllInventory, loadListProduct, getProductAll, delelteProduct, getPlan, refreshToken, } from '../../service/auth/AuthService';
import PopupUpLoad from './PopupUpLoad';
import { AlignType } from 'rc-table/lib/interface'
import { getAccessToken, setAccessToken } from '../../helper/tokenHelper';
import { TypeCurrent, TypeDataStorage } from '../../interface/manageProduct.interface';
import { TypeItem } from '../../interface/manage.interface';


const ManageProduct = () => {
    const [typeProduct, setTypeProduct] = useState("product");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalUpLoad, setIsModalUpLoad] = useState(false);
    const [isModalAddProduct, setIsModalAddProduct] = useState(false);
    const [listProduct, setListProduct] = useState([]);
    const [dataFile, setDataFile] = useState<any>()
    const [filterSearch, setFilterSearch] = useState<string>('');
    const [productFilter, setProductFilter] = useState<any>([])
    const [dataStoRemo, setDataStoRemo] = useState<any>([]);
    const [isSpin, setIsSpin] = useState(false);
    const navigate = useNavigate()
    const [total, setTotal] = useState(0);
    const [type, setType] = useState("add");
    const [dataSource, setDataSource] = useState<any>([])
    const [dataSourceSto, setDataSourceSto] = useState<any>([])
    const [dataFilter, setDataFilter] = useState<any>([])
    const [dataSourceProduct, setDataSourceProduct] = useState<any>(undefined)
    const [dataStorage, setDataStorage] = useState<any>([])
    const [totalStoredProduct, setTotalStoredProduct] = useState<number>(0);
    const [storageSearch, setStorageSearch] = useState<any>();
    const [statusOpenSearch, setStatusOpenSearch] = useState(true)


    const getListData = async () => {
        setIsSpin(true);
        try {
            const resAll = await getProductAll(1, 999999);
            const totalPr = resAll.data.results.data.reduce((item: number, current: TypeCurrent) => item + current.quantity, 0,);
            setTotalStoredProduct(totalPr);
            setListProduct(resAll.data.results.data);
            setTotal(resAll.data.results.total);
    
            // ---------------------
            let user = await profileLogin()
            if (user.data.message == "Token is Invalid") {
                // Refresh token
                const token = await refreshToken()
                if (token?.data?.access_token) {
                    setAccessToken(token?.data?.access_token)
                    user = await profileLogin()
                }
            }
            const lastData = user.data?.results?.warehu
            const dataView = await getPlan(lastData?.uuid);
            const storageData = dataView.data.results[0]?.list_row_by_zone;
            let dataStore: string[] = [];
            storageData.forEach((item: TypeDataStorage) => {
                return (item?.des.forEach((items: any) => {
                    dataStore = [...dataStore, ...items]
                }))
            });
            setStorageSearch(dataStore);
            setIsSpin(false);
        } catch (error) { 
            console.log(error); 
            setIsSpin(false);
        }
    }

    useEffect(() => {
        getListData();

    }, [])

    const handleClickMovement = () => {
        navigate('/inventory-management/manage-product/product-movement', { state: { item: dataSourceProduct } });
    }

    const handleClickModifyProduct = () => {
        setType("edit");
        setIsModalAddProduct(true);
    }

    const handleClickDeleteProduct = async () => {
        setIsSpin(true);
        try {
            await delelteProduct(productFilter[0].uuid);
            getListData();
            setProductFilter([]);
        } catch (error) { console.log(error); }
        setIsSpin(false);
    }

    const handleAddProduct = () => {
        setType("add");
        setIsModalAddProduct(true)
    }

    const handleCancelSend = () => {
        setIsModalVisible(false);
        setIsModalAddProduct(false)
    };

    const handleOKSend = (values: any) => {
        setIsModalVisible(false);
    };
    // modal up load file
    const handleShowUpLoad = async () => {

        setIsModalUpLoad(true)
    }
    const handleCancelUpLoad = () => {
        setIsModalUpLoad(false)
    }
    const handleOkUpLoad = async () => {
        const res = await loadListProduct({ csv_file: dataFile[0] });
        message.success(res.data.message);
        setIsModalUpLoad(false)
    }

    // click search
    const handleClickSearch = async () => {
        setIsSpin(true);
        const nameStorage = storageSearch.filter((item: TypeItem) => {
            return item.name === filterSearch
        })

        try {
            if (nameStorage.length > 0) {
                navigate(`/inventory-management/manage-product/storage-data-sheet?search=${filterSearch}`);
            }
            if (filterSearch == "") {
                setProductFilter([]);
            } else {
                const res = await getProductAllInventory(filterSearch);
                if (res.data.results?.type === "storage" && res.data.results?.products.length > 0) {
                    navigate(`/inventory-management/manage-product/storage-data-sheet?search=${filterSearch}`);
                } else if (res.data.results.type == "product" && res.data.results.products?.length === undefined) {
                    navigate(`/inventory-management/manage-product/product-data-sheet?search=${filterSearch}`);
                }
                if (res.data.results?.type === "storage" && res.data.results?.products?.length === 0) {
                    setDataStorage(res.data.results)
                    message.error("Not found")
                }
                setDataStoRemo(res.data.results)
                setTypeProduct(res.data.results.type);
                setFilterSearch("")
                if (res.data.results?.type === "product") {
                    const data = [];
                    data.push(res.data.results)
                    setDataSourceProduct(res.data.results)
                    setProductFilter(data)
                    setDataSource(res.data.results?.inventories.filter((item: any) => item?.item_number > 0))

                } else if (res.data.results?.type === "storage" && res.data.results?.products?.length === 0 ||
                res.data.results?.type === "storage" && res.data.results?.products?.length > 0) {
                    setDataStorage(res.data.results)
                    setDataSourceSto(res.data.results?.products.filter((item: any) => item?.quantity > 0))
                }
            }
            setIsSpin(false);
            setStatusOpenSearch(true);
        } catch (error) {
            if (nameStorage.length === 0) {
                message.error('Not found')
            }
            setIsSpin(false);
        }
    }
    const handleSearch = async (value: any) => {
        setFilterSearch(value?.trim());
        if (value == "") {
            setTypeProduct("product");
            setProductFilter([]);
        }
    }
    // enter
    const handleEnterSearch = (event: any) => {
        if (event.key === "Enter") {
            handleClickSearch()
        }

    }
    // download
    const handleDowload = async () => {
        fetch('https://warehudev-api.maxline.hu/api/download-sample-file',
            {
                headers:
                    new Headers({
                        'Authorization': 'Bearer ' + getAccessToken(),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
            })
            .then(response => {
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.click();
                });
                //window.location.href = response.url;
            });
    }
    const columns = [
        {
            title: 'Article no',
            dataIndex: 'article_no',
            key: 'article_no',
            align: 'center' as AlignType,
        },

        {
            title: 'Ean code',
            dataIndex: 'ean_code',
            key: 'ean_code',
            align: 'center' as AlignType,
        },
        {
            title: 'Producer',
            dataIndex: 'producer',
            key: 'producer',
            align: 'center' as AlignType,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center' as AlignType,
        },

    ];

    const columnsInventory = [
        {
            title: 'Storage place',
            dataIndex: 'storage_place',
            key: 'storage_place',
            align: 'center' as AlignType,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            align: 'center' as AlignType,
        },
        {
            title: 'Last modification ',
            dataIndex: 'updated_at',
            key: 'updated_at',
            align: 'center' as AlignType,
        },
        {
            title: 'Quantity',
            dataIndex: 'item_number',
            key: 'quantity',
            align: 'center' as AlignType,
        },
    ];

    const columnsProduct = [
        {
            title: 'Article no.',
            dataIndex: 'article_no',
            key: 'article_no',
            align: 'center' as AlignType,
            render: (article_no: string) => {
                return (
                    <Button
                        onClick={() => {
                            navigate(`/inventory-management/manage-product/product-data-sheet?article_no=${article_no}`);
                        }}
                    >
                        {article_no}
                    </Button>
                );
            }
        },
        {
            title: 'EAN code',
            dataIndex: 'ean_code',
            key: 'ean_code',
            align: 'center' as AlignType,
            render: (ean_code: string) => {
                return (
                    <Button
                        onClick={() => {
                            navigate(`/inventory-management/manage-product/product-data-sheet?ean_code=${ean_code}`);
                        }}
                    >
                        {ean_code}
                    </Button>
                );
            }
        },
        {
            title: 'Producer',
            dataIndex: 'producer',
            key: 'producer',
            align: 'center' as AlignType,
        },
        {
            title: 'Type ',
            dataIndex: 'name',
            key: 'name',
            align: 'center' as AlignType,
        },
        {
            title: 'Stored products',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center' as AlignType,
            render: (quantity: number) => {
                if (!quantity) quantity = 0;
                return `${quantity}` + " PC";
            }
        },
    ];

    const handleClickStorageRemoval = () => {
        navigate('/storage-removal', { state: { item: dataStoRemo } });
    }

    return (
        <>
            {
                isSpin ?
                    <div className='w-full h-full flex justify-center items-center'>
                        <Spin size="large" spinning={isSpin} />

                    </div>
                    :
                    <div className='px-4 pt-[4rem]'>
                        <div className=' w-[70%] 2xl:w-full'>
                            {
                                typeProduct == "product" || dataFilter?.length === 0 ?
                                    <>
                                        <h1 className='text-2xl mb-5'>Inventory Management</h1>
                                        <div>
                                            <div className='flex items-center mb-10 justify-between w-full'>
                                                <h1 className=' text-xl w-[15rem]'>Product search</h1>
                                                <div className=' w-full  mx-4 py-2'>
                                                    <Input className='h-[36px]' value={filterSearch} placeholder="Storage place, EAN code or Article no" 
                                                    onChange={(e) => handleSearch(e.target.value)} 
                                                    onKeyDown={handleEnterSearch} />
                                                </div>
                                                <Button
                                                    className="  pb-5 font-medium min-w-[8rem] text-xl !bg-button !border-button !text-white rounded !h-9 "
                                                    onClick={handleClickSearch}
                                                >
                                                    <div className='flex ' >
                                                        <i className='bx bx-search text-xl'></i>
                                                        <span className='ml-2 text-base font-semibold leading-5 ' >Search</span>
                                                    </div>
                                                </Button>
                                            </div>
                                            <div className='mb-8 flex justify-between  flex-start'>
                                                <Button
                                                    className="  pb-5 font-medium mr-2 text-xl !bg-button !border-button !text-white rounded !h-9  "
                                                    onClick={handleAddProduct}
                                                >
                                                    <div className='flex items px-10' >
                                                        <i className='bx bx-plus text-xl'></i>
                                                        <span className='ml-2  text-base font-semibold leading-5 ' >Add product</span>
                                                    </div>
                                                </Button>

                                                <Button
                                                    className="  pb-5 mx-2 font-medium  text-xl !bg-button !border-button !text-white rounded !h-9 "
                                                    onClick={handleShowUpLoad}
                                                >
                                                    <div className='flex items px-10' >
                                                        <i className='bx bx-upload text-2xl'></i>
                                                        <span className='ml-2 text-base font-semibold leading-5 ' >Load product list</span>
                                                    </div>
                                                </Button>


                                                <Button
                                                    className="  pb-5 ml-2 font-medium  text-xl !bg-button !border-button !text-white rounded !h-9"
                                                    onClick={handleDowload}
                                                >
                                                    <div className='flex items px-10' >
                                                        <i className='bx bx-download text-2xl'></i>
                                                        <span className='ml-2 text-base font-semibold leading-5 ' >Download sample file</span>
                                                    </div>
                                                </Button>
                                            </div>

                                        </div>

                                        <div className='flex w-full'>
                                            <p className='w-1/2 p-4 border-[1px] border-r-[0px] border-black font-semibold'>
                                                Number of recorded products: <span className='ml-10 text-red'>{total}</span>
                                            </p>
                                            <p className='w-1/2 p-4 border-[1px] border-black font-semibold'>
                                                Number of stored products: <span className='ml-10'> {totalStoredProduct}</span>
                                            </p>
                                        </div>
                                        <Spin size="large" spinning={isSpin}>
                                            {dataSourceProduct && typeProduct === "product" && statusOpenSearch ?
                                                <div>
                                                    <div>
                                                        <div className='flex w-full'>
                                                            <div className='w-2/5 mr-4'>
                                                                <h1 className='text-2xl mb-4'>Product data</h1>

                                                                <div  >
                                                                    <div className="flex items-center w-full">
                                                                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Article no.</p>
                                                                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{dataSourceProduct?.article_no}</p>
                                                                    </div>
                                                                    <div className="flex items-center w-full">
                                                                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>EAN code:</p>
                                                                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{dataSourceProduct?.ean_code}</p>
                                                                    </div>
                                                                    <div className="flex items-center w-full">
                                                                        <p className='w-1/2 border-[1px] border-b-0 m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Producer:</p>
                                                                        <p className='w-1/2 border-[1px] border-b-0 m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{dataSourceProduct?.producer}</p>
                                                                    </div>
                                                                    <div className="flex items-center w-full">
                                                                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Type:</p>
                                                                        <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{dataSourceProduct?.name}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='w-3/5 ml-4'>
                                                                <h1 className='text-2xl mb-4'>Inventory data</h1>
                                                                <div className='border-t-[1px] border-black border-l-[1px]'>
                                                                    <Table dataSource={dataSource} columns={columnsInventory} pagination={false} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=' w-full flex flex-start '>
                                                            <div className='my-10 flex w-full justify-between  flex-start'>
                                                                <Button
                                                                    className="  pb-5 mr-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9  "
                                                                    onClick={handleClickMovement}
                                                                >
                                                                    <div className='flex items px-4' >
                                                                        <i className='bx bx-transfer-alt text-2xl'></i>
                                                                        <span className='ml-2  text-base font-semibold leading-5 ' >Product movement</span>
                                                                    </div>
                                                                </Button>
                                                                <Button
                                                                    className="  pb-5 mx-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9  "

                                                                >
                                                                    <div className='flex items px-4' >
                                                                        <i className='bx bx-x text-2xl'></i>
                                                                        <span className='ml-2  text-base font-semibold leading-5 ' >Inactivate product</span>
                                                                    </div>
                                                                </Button>
                                                                <Button
                                                                    className="  pb-5 mx-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9 "
                                                                    onClick={handleClickModifyProduct}
                                                                >
                                                                    <div className='flex items px-4' >
                                                                        <i className='bx bx-edit text-2xl'></i>
                                                                        <span className='ml-2 text-base font-semibold leading-5 ' >Modify product</span>
                                                                    </div>
                                                                </Button>
                                                                <Button
                                                                    className="  pb-5 ml-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9"
                                                                    onClick={handleClickDeleteProduct}
                                                                >
                                                                    <div className='flex items px-4' >
                                                                        <i className='bx bxs-trash text-2xl'></i>
                                                                        <span className='ml-2 text-base font-semibold leading-5 ' >Delete product</span>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="mb-10 mt-4  ">
                                                    <h1 className='text-2xl mb-4'>Recorded product</h1>
                                                    <div className=' border-black '>
                                                        <Table dataSource={listProduct} columns={columnsProduct} loading={isSpin} bordered
                                                            pagination={{
                                                                pageSizeOptions: ["10", "20", "30"],
                                                                showSizeChanger: true
                                                            }}
                                                        />
                                                    </div>                                                 
                                                </div>
                                            }
                                        </Spin>
                                    </>

                                    :
                                    <>
                                        {<Spin size="large" spinning={isSpin}>

                                            <div className="w-full">
                                                <h1 className="text-2xl mb-6">Storage data sheet</h1>
                                                <div className=' mb-8'>

                                                    <div>
                                                        <div className="flex items-center w-full">
                                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Storage place: </p>
                                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] h-[42px] p-2 text-base font-semibold border-black ml-[-1px]'>{dataStorage?.storage}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=' mt-4'>
                                                    <div className='border-t-[1px] border-black border-l-[1px] '>
                                                        <Table dataSource={dataSourceSto} columns={columns} pagination={false} />
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <Button
                                                        className="  pb-5 mr-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9  "
                                                        onClick={handleClickStorageRemoval}
                                                    >
                                                        <div className='flex items px-4' >
                                                            <i className='bx bx-plus text-2xl'></i>
                                                            <span className='ml-2  text-base font-semibold leading-5 ' > Add product</span>
                                                        </div>
                                                    </Button>

                                                </div>
                                            </div>
                                        </Spin>
                                        }
                                    </>

                            }

                        </div>
                    </div >
            }
            <PopupProductAdd
                isModalAddProduct={isModalAddProduct}
                setIsModalAddProduct={setIsModalAddProduct}
                handleCancelSend={handleCancelSend}
                handleOKSend={handleOKSend}
                setListProduct={setListProduct}
                productFilter={productFilter}
                setProductFilter={setProductFilter}
                type={type}
                listProduct={listProduct}
                getListData={getListData}
                setStatusOpenSearch={setStatusOpenSearch}
                setTotal={setTotal}
            />
            <PopupUpLoad
                handleOkUpLoad={handleOkUpLoad}
                handleCancelUpLoad={handleCancelUpLoad}
                isModalUpLoad={isModalUpLoad}
                setDataFile={setDataFile}
            />
        </>
    )
}

export default ManageProduct


