import React, { useState } from 'react';
import { Button, Form, Input, message, Spin } from 'antd';
import { FolderOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { errorForgot } from '../../enum/forgot.error';
import { resetPasswordUser } from '../../service/auth/AuthService';
import { Format_Email } from '../../constant/forgot.constant';
import { TypeDataForgotPass } from '../../interface/reset.interface';
import { useNavigate } from 'react-router-dom';

export default function ResetPass() {
	const [isSpin, setIsSpin] = useState(false);
	const navigate = useNavigate();
	const [formForgot] = Form.useForm();

	const onFinish = (values: TypeDataForgotPass) => {
		setIsSpin(true);
		resetPasswordUser(values)
			.then((res: any) => {

				setIsSpin(false);
				message.success(res.data.message);
				formForgot.setFieldsValue({
					email: '',
				});
			})
			.catch((err: any) => {

				setIsSpin(false);
				message.error(err.response.data.message);
			});
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const handleGoBack = () => {
		navigate(-1);
	};
	return (
		<Spin size="small" spinning={isSpin} delay={1000}>
			<div className="bg_auth h-[100vh] flex items-center ">
				<div className="item_auth w-1/2 flex items-center m-auto h-[90%] rounded-lg overflow-hidden box shadow-lg shadow-gray-400 sm:h-[80%] sm:w-3/4">
					<div className="bg_left w-1/2 h-full lg:hidden"></div>
					<div className="w-1/2 h-full lg:w-full ">
						<Form
							name="basic"
							labelCol={{ span: 32 }}
							wrapperCol={{ span: 32 }}
							initialValues={{ remember: true }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
							layout="vertical"
							size="large"
							className=" w-full h-full bg-white rounded my-auto flex justify-center lg:flex lg:justify-center"
							form={formForgot}
						>
							<div className="p-5">
								<div className="flex items-center justify-center relative mb-5">
									<div className=" flex items-center">
										<ArrowLeftOutlined
											className="mr-[1rem] "
											onClick={() => {
												handleGoBack();
											}}
										/>
									</div>
									<h1 className="text-2xl font-sans text-center mb-0 sm:text-xl ">
										Forgot Password!
									</h1>
								</div>
								<p className="text-center sm:text-xs">
									Enter your email below to receive your password reset instructions.
								</p>
								<Form.Item
									name="email"
									className="item-form-input"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(new Error(errorForgot.EMAIL_NONE));
												} else if (!Format_Email.test(val)) {
													return Promise.reject(new Error(errorForgot.EMAIL_FORMAT));
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input
										prefix={<FolderOutlined className="mr-2 " />}
										placeholder="Please enter your email"
									/>
								</Form.Item>

								<Form.Item wrapperCol={{ offset: 32, span: 32 }} className="w-full">
									<Button type="primary" htmlType="submit" className="w-full rounded-md">
										<p className="m-auto sm:text-sm">Submit</p>
									</Button>
								</Form.Item>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</Spin>
	);
}
