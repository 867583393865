import React, { useEffect, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spin, Input, message } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { getPlan, profileLogin, getProductAllInventory, getProductAll, } from '../../service/auth/AuthService';
import { CurrentData, DataViewItem, ItemStorageData, TotalItem, TypeItem } from '../../interface/home.interface';
import { deleteAccessToken } from '../../helper/tokenHelper';

const Home = () => {
	const navigate = useNavigate()
	const [listDataShow, setListDataShow] = useState<any>([]);
	const [totalNum, setTotalNum] = useState<number>(0)
	const [nameWH, setNameWH] = useState('')
	const [storageSearch, setStorageSearch] = useState<any>();
	const [filterSearch, setFilterSearch] = useState<string>('');
	const [totalStoreProduct, setTotalStoreProduct] = useState<number>(0);
	const [isSpin, setIsSpin] = useState(true);
	const getListData = async () => {

		try {
			const user = await profileLogin()
			
			const lastData = user.data?.results?.warehu
			setNameWH(lastData?.name)
			const dataView = await getPlan(lastData?.uuid);
			const storageData = dataView.data.results[0]?.list_row_by_zone;
			let dataStore: ItemStorageData[] = [];
			storageData.forEach((item: ItemStorageData) =>
				item?.des.forEach((items: any) => {
					dataStore = [...dataStore, ...items]
				}));
			setStorageSearch(dataStore)
			const res = await getProductAll(1, 999999);
			const totalPr = res.data?.results?.data.reduce((item: number, current: CurrentData) => item + current.quantity, 0);
			setTotalStoreProduct(totalPr)
			setIsSpin(false);
			setListDataShow(dataView.data.results)
			const arr = dataView.data.results.reduce((target: any, item: DataViewItem) => {
				target = target.concat(item.list_row_by_zone);
				return target
			}, [])
			const total = arr.reduce((t: number, item: TotalItem) => {
				t += item.des.reduce((sum: number, d: any) => {
					return sum += d.length
				}, 0)
				return t
			}, 0)
			setTotalNum(total)
		} catch (error: any) {
			setIsSpin(false);
			if (error.response?.status == 403) {
				deleteAccessToken();
				navigate('/authenticate')
			}
		}
	}
	// show total storage places
	useEffect(() => {
		getListData();

	}, [])
	const onClickHandler = () => {
		navigate('/warehouse-planning');
	}
	const handleClickSearch = async () => {
		setIsSpin(true);
		const nameStorage = storageSearch.filter((item: TypeItem) => {
			return(item.name === filterSearch)
		} );
		try {
			if (nameStorage.length > 0) {
				navigate(`/inventory-management/manage-product/storage-data-sheet?search=${filterSearch}`);
			}

			const res = await getProductAllInventory(filterSearch);
			const results = res.data.results;
			setStorageSearch(results.products?.length);
			if (results?.type === "storage" && results.products?.length === 0 && nameStorage?.length === 0) {
				message.error('Not found');
				setIsSpin(false);
			} else if (results?.type === "storage" && results.products?.length > 0 && nameStorage?.length > 0 || results?.type === "storage" && results.products?.length === 0 && nameStorage?.length > 0) {
				navigate(`/inventory-management/manage-product/storage-data-sheet?search=${filterSearch}`);
			} else if (results.type == "product" && results.products?.length === undefined) {
				navigate(`/inventory-management/manage-product/product-data-sheet?search=${filterSearch}`);
			}
			setIsSpin(false);
		} catch (error) {
			if (nameStorage.length === 0) {
				message.error('Not found')
			}
			setIsSpin(false);
		}
	}
	const handleSearch = async (value: string) => {
		setFilterSearch(value?.trim());
	}

	// enter
	const handleEnterSearch = (event: any) => {
		if (event.key === "Enter") {
			handleClickSearch()
		}
	}
	// Click storage to starage data sheet
	const handleClickStorage = (j:TypeItem) => {
		navigate(`/inventory-management/manage-product/storage-data-sheet?search=${j.name}`);
	}
	return (
		<>
			{isSpin ?
				<div className='w-full h-full flex justify-center items-center'>
					<Spin size="large" spinning={isSpin} />

				</div>
				:
				<div className={`px-4 pt-[4rem]`}>
					<h1 className="text-2xl mb-[1.5rem]">Home page</h1>
					<div className='flex items-center justify-between mb-4'>
						<h2 className='text-xl'>Name of warehouse: <>{nameWH ? nameWH : ' There is no saved warehouse'}</> </h2>
						<div>
							{nameWH && <Button
								className=" 2xl:mt-2 mt-5 pb-5 font-medium min-w-[6rem] text-xl !bg-button border !border-button !text-white rounded  "
								onClick={onClickHandler}
							>
								<div className='flex px-2 ' >
									<i className='bx bx-edit text-2xl'></i>
									<span className='ml-2 text-base font-semibold ' >Edit</span>
								</div>
							</Button>
							}
						</div>
					</div>
					<div className='flex items-center mb-6 justify-between w-[70%]'>
						<h1 className=' text-xl min-w-[9rem]'>Product search</h1>
						<div className=' w-full  mx-4 py-2'>
							<Input className='h-[36px]' placeholder="Storage place, EAN code or Article no" value={filterSearch}
								onChange={(e) => handleSearch(e.target.value)} onKeyDown={handleEnterSearch} />
						</div>
						<Button
							className="  pb-5 font-medium min-w-[8rem] text-xl !bg-button !border-button !text-white !h-9 rounded "
							onClick={handleClickSearch}
						>
							<div className='flex items-center ' >
								<i className='bx bx-search text-xl'></i>
								<span className='ml-2 text-base font-semibold leading-5 ' >Search</span>
							</div>
						</Button>
					</div>
					<div className='flex w-full'>
						<p className='w-1/2 p-4 border-[1px] border-r-[0px] border-black font-semibold'>
							Number of storage places: <span className='ml-10 text-red'>{totalNum}</span>
						</p>
						<p className='w-1/2 p-4 border-[1px] border-black font-semibold'>
							Number of stored products: <span className='ml-10'>{totalStoreProduct}</span>
						</p>
					</div>
					{listDataShow && listDataShow.length > 0 ?
						<Paragraph style={{ minWidth: 400, marginTop: 50, }}>
							<div className="mx-auto   border-2 border-gray p-4 pb-0 w-full   ">
								{listDataShow?.map((item: DataViewItem, index: number) => {
									return (
										<div key={index}>
											{item?.list_row_by_zone.map((items: ItemStorageData, inx: number) => {
												return (
													<div key={inx}>
														<div className=' flex mb-4 items-center '>
															<div>
																<h5 className=' mb-0 h-full  text-base w-[3rem] ' >{items.name}.</h5>
															</div>
															<div className=' border-collapse flex  '>
																{items.des.map((i: TypeItem[], k: number) => {
																	const obj = [...i].reverse()
																	return (
																		<div key={k} className=" flex items-end">
																			<div className=' flex flex-col' >{obj.map((j: TypeItem, l: number) => {
																				return (
																					<div key={l}
																					style={{backgroundColor: `${j.color}`}}
																						className={`${items.level === "1" ? "p-4" : 'px-4 py-1'}  hover:text-bodyMenu  duration-300 border-[1px] min-w-[90px] border-black mb-[-1px] mr-[-1px] cursor-pointer`
																						} 
																						onClick={() => handleClickStorage(j)}> 
																						{j.name}
																						</div>
																				)
																			})}
																			</div>
																		</div>
																	)
																})}
															</div>
														</div>
													</div>
												)
											})}
										</div>
									)
								})}
							</div>
						</Paragraph>
						:
						<div className='mt-10'>
							<Button
								className=" 2xl:mt-2 mt-5 pb-5 font-medium min-w-[8rem] text-xl !bg-button !border-button !text-white rounded !h-9 "
								onClick={() => navigate('/warehouse-planning')}
							>
								<div className='flex px-6 '>
									<i className='bx bxs-pencil text-2xl text-white'></i>
									<span className='ml-2 text-base font-semibold'>Plan your warehouse!</span>
								</div>
							</Button>
						</div>
					}

				</div >
			}

		</>
	)

};

export default Home;
