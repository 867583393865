import apiClient from '../../config/apiClient';
import { AuthInterface, SocialLoginAuth, TypeRegisterUser } from '../../interface/auth.interface';
import { TypeChangePassword } from '../../interface/change.interface';
import { TypeProductToInventory } from '../../interface/manage.interface';
import { TypeAddProduct, TypeAddProductGroup } from '../../interface/manageProduct.interface';
import { TypeDataForgotPass } from '../../interface/reset.interface';
import { TypePlanWarehu } from '../../interface/warehousePlanning.interface';

export const loginUser = async (params: AuthInterface) => {

	return await apiClient.post('/login', params);
};

export const logoutUser = async () => {
	return await apiClient.post('/logout');
};

export const updatePassword = async (params: TypeChangePassword) => {
	return await apiClient.post('/update-password', params);
};

export const registerUser = async (params: TypeRegisterUser) => {
	return await apiClient.post('/register', params);
};

export const resetPasswordUser = async (params: TypeDataForgotPass) => {

	return await apiClient.post('/send-email-forgot-pass', params);
};

export const socialLoginUser = async (params: SocialLoginAuth) => {
	return await apiClient.post('/login-social', params);
};
export const profileLogin = async () => {
	return await apiClient.get('/get-user');
};

export const refreshToken = async () => {
	return await apiClient.post('/refresh-token');
};

export const getPlan = async (uuid: string) => {
	return await apiClient.get(`/get-plan-wh/${uuid}`);
};
export const getListWareHouse = async () => {
	return await apiClient.get('/get-list-wh');
};
export const createPlanWareHouse = async (params: TypePlanWarehu) => {
	return await apiClient.post('/create-plan-wh', params);
};
// product
export const downloadSampleFile = async () => {
	return await apiClient.get('/download-sample-file')
}
export const downloadSampleFilePicking = async () => {
	return await apiClient.get('/download-sample-file-picking')
}
export const getProductStorageInventory = async (search: string) => {
	return await apiClient.get(`/get-product-storage-inventory/${search}`)
}
export const getProductAllInventory = async (search: string) => {
	return await apiClient.get(`/get-product-all-inventory/${search}`)
}
export const getProductAll = async (page: any = 1, size: any = 10,) => {
	return await apiClient.get(`/get-all-products?page=${page}&size=${size}`)
}
export const getListProductAll = async () => {
	return await apiClient.get(`/get-all-products`)
}
export const addProduct = async (params: TypeAddProduct,) => {
	return await apiClient.post('/add-product', params)
}
export const delelteProduct = async (uuid: string) => {
	return await apiClient.delete(`/del-product/${uuid}`)
}
// product group
export const addProductGroup = async (params: TypeAddProductGroup) => {
	return await apiClient.post('/add-product-group', params)
}
export const delelteProductGroup = async (uuid: string) => {
	return await apiClient.delete(`/del-product-group/${uuid}`)
}
export const getListProductGroup = async () => {
	return await apiClient.get(`/get-list-product-group`)
}
// import , export
export const loadListProduct = async (params: any) => {
	const formData = new FormData();
	for (const [key, value] of Object.entries(params)) {
		formData.append(key, value as string);
	}
	return await apiClient.post(`/load-list-product`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

}

export const addProductToInventory = async (params: TypeProductToInventory) => {
	return await apiClient.post('/add-product-to-inventory', params)
}

export const productMovementInventory = async (params: TypeProductToInventory) => {
	return await apiClient.post('/product-movement-inventory', params)
}

// picking
export const productPicking = async () => {
	return await apiClient.get(`/product-picking`)
}

export const addProductPicking = async (params: any) => {
	return await apiClient.post('/add-product-picking', params)
}

export const delProductPicking = async (product_picking_id: string) => {
	return await apiClient.delete('/del-product-picking/' + product_picking_id)
}

export const delPicking = async (picking_id: string) => {
	return await apiClient.delete('/del-picking/' + picking_id)
}

