import React from 'react';

const ProductReceiving = () => {
	return (
		<>
			<div className="px-4 pt-[4rem]">
				<h1 className="text-2xl mb-4">Product Receiving</h1>
			</div>
		</>
	);
};

export default ProductReceiving;
