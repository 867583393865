import React, { useEffect, useState } from 'react'
import { Table, message, Spin, Button } from 'antd'
import { AlignType } from 'rc-table/lib/interface'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { delelteProduct, getProductAll, getProductAllInventory } from '../../service/auth/AuthService';
import PopupProductAdd from '../manage-product/PopupProductAdd';
import PopupDelete from '../popup-delete/PopupDelete';
import moment from 'moment';
import { TypeProductMovement } from '../../interface/manage.interface';
import { ListOfStorageItem } from '../../interface/picking.interface';


const ProductDataSheet = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const article_no = searchParams.get('article_no');
    const ean_code = searchParams.get('ean_code');
    const search = searchParams.get('search');
    const [isSpin, setIsSpin] = useState(false);
    const [productFilter, setProductFilter] = useState<any>([])
    const [dataSource, setDataSource] = useState<any>([])
    const [dataSourceProductMovement, setDataSourceProductMovement] = useState<any>([])
    const [isModalAddProduct, setIsModalAddProduct] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
    const [type, setType] = useState('add');
    const [total, setTotal] = useState(0);

    const navigate = useNavigate()
    const columns = [
        {
            title: 'Storage place',
            dataIndex: 'storage_place',
            key: 'storage_place',
            align: 'center' as AlignType,
        },
        {
            title: 'Quantity',
            dataIndex: 'item_number',
            key: 'quantity',
            align: 'center' as AlignType,
        },
        {
            title: 'Last modification ',
            dataIndex: 'updated_at',
            key: 'modification',
            sorter: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            render: (_: number, item: ListOfStorageItem) => {
                return (
                    <div>
                        { ` ${moment(item.created_at).format('HH:mm:ss DD-MM-YYYY')}` }
                    </div>
                );
            },
            align: 'center' as AlignType,
        },
    ];

    const columnsProductMovement = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            align: 'center' as AlignType,
            render: (_: number, item: TypeProductMovement) => {
                return (
                    <div>
                        { ` ${moment(item.date).format('HH:mm:ss DD-MM-YYYY')}` }
                    </div>
                );
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center' as AlignType,
        },
        {
            title: 'Storage place',
            dataIndex: 'place',
            key: 'place',
            align: 'center' as AlignType,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            align: 'center' as AlignType,
            render: (_: number, item: TypeProductMovement) => {
                return (
                    <div className={`${item.operation==="Removal" ? 'text-red font-medium' : "" }`}>
                        { item.operation }
                    </div>
                );
            },
        },

    ];

    useEffect(() => {
        getProduct();
    }, [])
    
    const getProduct = async () => {
        setIsSpin(true);
        try {
            if (!article_no && !ean_code && !search) {
                setProductFilter([]);
            } else {
                let filterSearch = "";
                if (article_no) { filterSearch = article_no }
                else if (ean_code) { filterSearch = ean_code || '' }
                else {
                    filterSearch = search || "";
                }
                const res = await getProductAllInventory(filterSearch);
                const results = res.data.results;
                const dataArray = [];
                dataArray.push({ uuid: results?.uuid, article_no: results?.article_no, ean_code: results?.ean_code, producer: results?.producer, name: results?.name, group_id: results?.group_id })
                setProductFilter(dataArray);

                setDataSource(results?.inventories.filter((item: any) => item?.item_number > 0));
                setTotal(results?.movements.filter((item: any) => item?.quantity > 0).length);
                setDataSourceProductMovement(results?.movements.filter((item: any) => item?.quantity > 0));
            }

            setIsSpin(false);

        } catch (error) {
            message.error('Not found')
            setIsSpin(false);
        }
    }
    //  click popup edit
    const handleClickModifyProduct = () => {
        setType('edit');
        setIsModalAddProduct(true);
    }
    const handleCancelSend = () => {
        setIsModalVisible(false);
        setIsModalAddProduct(false)
    };

    const handleOKSend = (values: any) => {
        setIsModalVisible(false);
    };
    //  popup delete
    const handleCancelDelete = () => {
        setIsModalVisibleDelete(false);
    };
    const handleOKDelete = async () => {
        setIsSpin(true)
        try {
            const res = await delelteProduct(productFilter[0]?.uuid);
            message.success(res.data.message)
            setIsModalVisibleDelete(false);
            getProductAll(1, 999999);
            navigate('/inventory-management/manage-product')
            setIsSpin(false)
        } catch (error) {
            setIsSpin(false)
        }
    }
    const handleDelete = () => {

        setIsModalVisibleDelete(true);
    }
    return (
        <>
            {isSpin ?
                <div className='w-full h-full flex justify-center items-center'>
                    <Spin size="large" spinning={isSpin} />

                </div>
                :
                <Spin size="large" spinning={isSpin}>

                    <div className="px-4 pt-[4rem]">
                        <h1 className="text-2xl mb-4">Product data sheet</h1>
                        <div className='w-1/2 mb-4'>
                            <h1 className='text-xl mb-4'>Product data</h1>
                            {
                                productFilter.length > 0 ?

                                    <div className='border-black'>
                                        <div className="flex items-center w-full">
                                            <p className='w-1/2 border-[1px] border-b-[0px] m-0 mb-[-1px] p-2 text-base font-semibold '>Article no.</p>
                                            <p className='w-1/2 border-[1px] border-b-[0px] m-0 mb-[-1px] p-2 text-base font-semibold  ml-[-1px]'>{productFilter[0]?.article_no}</p>
                                        </div>
                                        <div className="flex items-center w-full">
                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold '>EAN code:</p>
                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold  ml-[-1px]'>{productFilter[0]?.ean_code}</p>
                                        </div>
                                        <div className="flex items-center w-full">
                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold '>Producer:</p>
                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold  ml-[-1px]'>{productFilter[0]?.producer}</p>
                                        </div>
                                        <div className="flex items-center w-full">
                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] border-t-0 p-2 text-base font-semibold '>Type:</p>
                                            <p className='w-1/2 border-[1px] m-0 mb-[-1px] border-t-0 p-2 text-base font-semibold  ml-[-1px]'>{productFilter[0]?.name}</p>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                            }

                        </div>
                        <div className=' 2xl:w-full w-[80%]'>
                            <h1 className='text-xl mb-4'>Inventory Data</h1>
                            <div className=' border-black '>
                                <Table dataSource={dataSource} columns={columns} bordered
                                    pagination={{ pageSizeOptions: ["10", "20", "30"], showSizeChanger: true }}
                                />
                            </div>

                        </div>

                        <div className=' 2xl:w-full w-[80%] mt-4'>
                            <h1 className='text-xl mb-4'>Product movement</h1>
                            <div className=' border-black '>
                                <Table dataSource={dataSourceProductMovement} columns={columnsProductMovement} bordered
                                    pagination={{ pageSizeOptions: ["10", "20", "30"], showSizeChanger: true }}
                                />
                            </div>
                        </div>

                        <div className=' w-full flex flex-start '>
                            <div className='my-10 flex w-80% justify-between  flex-start'>

                                <Button
                                    className="  pb-5 mr-2 font-medium text-xl rounded !bg-button border !border-button !h-9 !text-white "
                                    onClick={handleClickModifyProduct}
                                >
                                    <div className='flex items px-4' >
                                        <i className='bx bx-edit text-2xl'></i>
                                        <span className='ml-2 text-base font-semibold leading-5 ' >Modify product</span>
                                    </div>
                                </Button>
                                <Button
                                    className="  pb-5  font-medium text-xl rounded !bg-button border !border-button !h-9 !text-white"
                                    onClick={handleDelete}
                                >
                                    <div className='flex items px-4' >
                                        <i className='bx bxs-trash text-2xl'></i>
                                        <span className='ml-2 text-base font-semibold leading-5 ' >Delete product</span>
                                    </div>
                                </Button>
                            </div>
                        </div>

                    </div>
                    <PopupProductAdd
                        isModalAddProduct={isModalAddProduct}
                        setIsModalAddProduct={setIsModalAddProduct}
                        handleCancelSend={handleCancelSend}
                        handleOKSend={handleOKSend}
                        // setListProduct={setListProduct}
                        productFilter={productFilter}
                        setProductFilter={setProductFilter}
                        type={type}
                    />
                    <PopupDelete
                        handleCancelDelete={handleCancelDelete}
                        handleOKDelete={handleOKDelete}
                        isModalVisibleDelete={isModalVisibleDelete}
                        dataItem={productFilter}
                        isSpin={isSpin}
                        title="product" />
                </Spin>
            }
        </>
    )
}

export default ProductDataSheet
function getDataGroup() {
    throw new Error('Function not implemented.');
}

