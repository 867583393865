import React, { useEffect, useState } from 'react'
import { Button, message, Pagination, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DataType } from '../../interface/home.interface';
import '../../pages/scss/style.scss'
import { delelteProductGroup, getListProductGroup } from '../../service/auth/AuthService';
import PopupProductGroup from '../manage-product/PopupProductGroup';
import PopupDelete from '../popup-delete/PopupDelete';
const ManageProductGroups = () => {
    const [dataGroup, setDataGroup] = useState([]);
    const [dataItem, setDataItem] = useState<any>();
    const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSpin, setIsSpin] = useState(true);
    const getDataGroup = async () => {
        try {
            const res = await getListProductGroup();
            setDataGroup(res?.data.results);
            setIsSpin(false);
        } catch (error) {
            setIsSpin(false);
        }
    }
    // popup delete manage product group
    const handleCancelDelete = () => {
        setIsModalVisibleDelete(false);
    };
    const handleOKDelete = async () => {
        setIsSpin(true)
        try {
            const res = await delelteProductGroup(dataItem?.uuid);
            message.success(res.data.message)
            setIsModalVisibleDelete(false);
            getDataGroup()
            setIsSpin(false)
        } catch (error: any) {
            setIsModalVisibleDelete(false);
            setIsSpin(false)
            message.error(error.response.data.message)
        }
    }
    const handleDelete = (item: any) => {
        setDataItem(item);
        setIsModalVisibleDelete(true);
    }
    // popup add new group
    const handleAddGroup = () => {
        setIsModalVisible(true)
    }
    const handleCancelSend = () => {
        setIsModalVisible(false);
    };


    const handleOKSend = (values: any) => {
        setIsModalVisible(false);
        getDataGroup()
    };
    useEffect(() => {
        getDataGroup()
    }, [])

    const columns: ColumnsType<DataType> = [
        {
            title: '#',
            dataIndex: 'ỉndex',
            key: 'index',
            className: "text-center font-semibold p-4 ",
            align: 'center',
            render: (text, object, index) => index + 1,

        },
        {
            title: 'Name of product group',
            dataIndex: 'name',
            key: 'name',
            className: "text-center ",
            align: 'center',
        },
        {
            title: 'No. of product types',
            dataIndex: 'number_of_types',
            key: 'number_of_types',
            align: 'center',
            className: "text-center ",
            render: (number_of_type) => {
                return (<div>{number_of_type ? `${number_of_type}` : "0"}</div>)
            }
        },
        {
            title: 'Product quantity ',
            dataIndex: 'quantity',
            key: 'quantity',
            className: "text-center ",
            align: 'center',
            render: (quantily, item) => {
                return (<div>{quantily ? `${quantily} pc` : "0 pc"}</div>)
            }
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            className: "text-center ",
            align: 'center',
            render: (_btn: any, item: any) => {
                return (
                    <>
                        <Button type="primary" style={{ backgroundColor: '#468da5', borderColor: '#468da5' }} onClick={() => handleDelete(item)} >
                            <div className='flex items-center'>
                                <i className='bx bx-trash text-2xl mr-2'></i>
                                Delete product
                            </div>
                        </Button>
                    </>
                );
            },
        },
    ];


    return (
        <>{isSpin ?
            <div className='w-full h-full flex justify-center items-center'>
                <Spin size="large" spinning={isSpin} />

            </div>
            :
            <div className='px-4 pt-[4rem]'>
                <h1 className='text-2xl mb-6'>Manage product group</h1>
                <div className='flex items-center mb-8 justify-between w-[50%] 2xl:w-[75%] '>
                    <Button
                        className=" ml-2  pb-2 font-medium min-w-[7rem] text-xl  "
                        style={{ backgroundColor: '#468da5', borderColor: '#468da5', color: '#fff', borderRadius: '4px', paddingLeft: '4px', height: '36px' }}
                        // onClick={() => navigate('/inventory-management/manage-product-groups')}
                        onClick={() => handleAddGroup()}
                    >
                        <div className='flex items px-6' >
                            <i className='bx bx-plus text-2xl'></i>
                            <span className=' ml-2  text-base font-semibold leading-5 ' >Add new product group</span>
                        </div>
                    </Button>
                </div>
                <div className='2xl:w-full w-[80%]  mb-20'>
                    <Table dataSource={dataGroup} columns={columns} pagination={{
                        pageSizeOptions: ["10", "20", "30"],
                        showSizeChanger: true
                    }} bordered />
                </div>
            </div>


        }
            <PopupProductGroup
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                handleCancelSend={handleCancelSend}
                handleOKSend={handleOKSend}
                setDataGroup={setDataGroup}
                type={1}
            />

            {/* <PopupDeleteProductGroup
                handleCancelDelete={handleCancelDelete}
                handleOKDelete={handleOKDelete}
                isModalVisibleDelete={isModalVisibleDelete}
                dataItem={dataItem}
                isSpin={isSpin}
            /> */}
            <PopupDelete
                handleCancelDelete={handleCancelDelete}
                handleOKDelete={handleOKDelete}
                isModalVisibleDelete={isModalVisibleDelete}
                dataItem={dataItem}
                isSpin={isSpin}
                title="group-product" />
        </>
    )
}

export default ManageProductGroups
