import React, { useEffect, useState } from 'react'
import { Button, Form, message, Select, Spin, Table, Space, InputNumber } from 'antd'

import { MinusCircleOutlined, } from '@ant-design/icons';
import { productPicking, getProductAll, addProductPicking, delPicking, delProductPicking, } from '../../service/auth/AuthService';
import { AlignType } from 'rc-table/lib/interface'
import PopupDeletePicking from './PopupDeletePicking';
import PopupDeletePickingProduct from './PopupDeletePickingProduct';
import { getAccessToken, } from '../../helper/tokenHelper';
import { ListOfStorageItem, TypeDataProduct, TypeDataProductPicking, TypeProductList, TypeProducts } from '../../interface/picking.interface';



const Picking = () => {
    const [isModalVisibleDelete, setIsModalVisibleDelete] = useState(false);
    const [isModalVisibleDeletePickingProduct, setIsModalVisibleDeletePickingProduct] = useState(false);
    const [dataItemDelete, setDataItemDelete] = useState({ uuid: "", picking_uuid: "" });
    const [isModalUpLoad, setIsModalUpLoad] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [listPicking, setListPicking] = useState<any>([])
    const [productFilter, setProductFilter] = useState<any>([])
    const [disable, setDisable] = useState<any>(true);
    const [isSpin, setIsSpin] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [form] = Form.useForm();
    const [articleProducts, setArticleProducts] = useState<any>([])
    const [eanProducts, setEanProducts] = useState<any>([])
    const [productData, setProductData] = useState<any>({})
    const [proDataOpen, setProDataOpen] = useState(false)
    const [productsSelectInit, setProductsSelectInit] = useState([
        { article_no: '', ean_code: '', quantity: 1, product_id: "" },
        { article_no: '', ean_code: '', quantity: 1, product_id: "" },
        { article_no: '', ean_code: '', quantity: 1, product_id: "" },
    ])
    const [idProduct, setIdProduct] = useState<string>();
    const [pickingUUID, setPickingUUID] = useState<string>('');
    const getListData = async () => {
        try {
            //Get all product 
            // setIsSpin(true);
            const resAllProduct = await getProductAll(1, 999999);
            const articleProducts = resAllProduct.data.results?.data.filter((item: TypeDataProduct) => item.quantity > 0).map((itemChild: TypeDataProduct) => (
                {
                    value: itemChild.uuid,
                    label: itemChild.article_no,
                    quantity: itemChild.quantity,
                }
            ))
            setArticleProducts(articleProducts)
            const eanProducts = resAllProduct.data.results?.data.filter((item: TypeDataProduct) => item.quantity > 0).map((itemChild: TypeDataProduct) => ({
                value: itemChild.uuid,
                label: itemChild.ean_code,
                quantity: itemChild.quantity,
            }))
            setEanProducts(eanProducts);
        } catch (error) { console.log(error); }
        // setIsSpin(false);
    }
    const getListProductPicking = async () => {
        setIsSpin(true);
        try {
            const res = await productPicking()
            const { total, current_page } = res.data.results;
            setListPicking(res.data.results.data);
            setTotal(total);
            setCurrentPage(current_page);
            setIsSpin(false);
        } catch (error) {
            console.log(error);
            setIsSpin(false);
        }
    }
    useEffect(() => {
        getListData();
        getListProductPicking()
        // getTotalStoreProduct();
    }, [])

    useEffect(() => {
        if (pickingUUID) {
            const item = listPicking.find((e: any) => e.uuid == pickingUUID); ``
            setProductData(item)
        }
    }, [listPicking])

    const onFinish = async (value: any = {}) => {
        const products = value?.products.filter((item: TypeProducts) => item.article_no !== '')
        const values = { "uuid": "", "products": products };
        try {
            setIsSpin(true);
            const res = await addProductPicking(values);
            await getListProductPicking()
            message.success(res.data.message)
            setIsSpin(false);
            setDisable(true);
            // const products = { products : productsSelectInit};
            setProductsSelectInit([
                { article_no: '', ean_code: '', quantity: 1, product_id: "" },
                { article_no: '', ean_code: '', quantity: 1, product_id: "" },
                { article_no: '', ean_code: '', quantity: 1, product_id: "" },
            ])
            form.resetFields();
        } catch (error) {
            message.error('Add product picking unsuccess!')
            setIsSpin(false);
        }
    }

    // modal up load file
    const handleShowUpLoad = async () => {
        setIsModalUpLoad(true)
    }
    const handleChangeFormItem = async (product_id: string, index: number) => {
        const ean_code = eanProducts.find((e: any) => e.value == product_id);
        const article_no = articleProducts.find((e: any) => e.value == product_id);
        productsSelectInit[index].article_no = article_no?.label;
        productsSelectInit[index].ean_code = ean_code?.label;
        productsSelectInit[index].product_id = product_id;
        setProductsSelectInit(productsSelectInit);
        const products = { products: productsSelectInit };
        form.setFieldsValue(products);
        if (productsSelectInit[index].article_no === "" || productsSelectInit[index].ean_code === "") {
            setDisable(true);
        } else {
            setDisable(false);
        }

    }
    const handleChangeQuantity = async (quantity: any, index: number) => {

        productsSelectInit[index].quantity = quantity;
        setProductsSelectInit(productsSelectInit);
        const products = { products: productsSelectInit };
        form.setFieldsValue(products);
    }

    const removeRow = async (index: number) => {
        productsSelectInit.splice(index, 1);
        productsSelectInit.map((item: TypeProducts) => {
            if (item.article_no === "" || item.ean_code === "") {
                setDisable(true);
            } else {
                setDisable(false);
            }
        })
        const products = { products: productsSelectInit };
        if (products.products.length > 0) {
            form.setFieldsValue(products);
        }
    }
    const addRow = async () => {
        productsSelectInit.push({ article_no: '', ean_code: '', quantity: 1, product_id: "" });
        productsSelectInit.map((item: TypeProducts) => {
            if (item.article_no === "" || item.ean_code === "") {
                setDisable(true);
            }
        })
        const products = { products: productsSelectInit };
        form.setFieldsValue(products);
    }
    const handleCancelDelete = () => {
        setIsModalVisibleDelete(false);
        setIsModalVisibleDeletePickingProduct(false);
    };
    const handleOKDelete = async () => {
        setIsModalVisibleDelete(false);
        setIsSpin(true);
        try {
            await delPicking(dataItemDelete.uuid);
            await getListProductPicking();
            setProDataOpen(false);
            setIsSpin(false);
        }
        catch (error) { setIsSpin(false); }
        
    }

    const handleOKDeletePickingProduct = async () => {
        // Delete picking product
        setIsModalVisibleDeletePickingProduct(false);
        setIsSpin(true);
        try {
            const res = await delProductPicking(dataItemDelete.uuid);
            message.success(res.data.message)
            await getListProductPicking();
            setProDataOpen(!proDataOpen);
            setIsSpin(false)
        }
        catch (error) { setIsSpin(false);}
    }

    const handleCreateManual = () => {
        setIsCreated(!isCreated)
    }

    const handleClickProductList = (item: TypeProductList) => {
        // const dataFilter = item?.product_pickings?.filter((item: any) => item.listOfStorage?.length > 0);
        setProductFilter(item?.product_pickings);
        if (item.uuid === idProduct) {
            setProDataOpen(!proDataOpen)
        } else {
            setProDataOpen(true)
        }
        setIdProduct(item?.uuid)
        setProductData(item)
        setPickingUUID(item.uuid);
    }
    const handleClickDeletePicking = (item: any) => {
        setIsModalVisibleDelete(true);
        setDataItemDelete(item);
    }

    const handleClickDeletePickingProduct = (item: any) => {
        setIsModalVisibleDeletePickingProduct(true);
        setDataItemDelete(item);
    }
    // download
    const handleDowload = async () => {
        fetch('https://warehudev-api.maxline.hu/api/download-sample-file-picking',
            {
                headers:
                    new Headers({
                        'Authorization': 'Bearer ' + getAccessToken(),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
            })
            .then(response => {
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.click();
                });
                //window.location.href = response.url;
            });
    }


    const columnsProduct = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
            align: 'center' as AlignType,

        },
        {
            title: 'Code of picking',
            dataIndex: 'code',
            key: 'code',
            align: 'center' as AlignType,

        },
        {
            title: 'Date of creation',
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'center' as AlignType,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as AlignType,
            render: (article_no: string, item: TypeProductList) => {
                return (
                    <div className={`${item.status === "Finished" ? 'text-green font-semibold' : ''}`}>
                        {item.status === "Finished" ? 'Completed' : item.status}
                    </div>
                )
            }
        },
        {
            title: 'Product list',
            dataIndex: 'product_list',
            key: 'product_list',
            align: 'center' as AlignType,
            render: (article_no: string, item: TypeProductList) => {
                return (
                    <Button
                        className="  pb-5 font-medium mr-2 text-2xl rounded !h-9 !text-white !bg-button border !border-button  "
                        onClick={() => handleClickProductList(item)}
                    >
                        <div className='flex' >
                            <i className='bx bx-list-ul text-2xl'></i>
                            <span className='ml-2 text-base font-semibold ' >Product list</span>
                        </div>
                    </Button>
                );
            }
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center' as AlignType,
            render: (article_no: string, item: TypeProductList) => {
                return (
                    <Button
                        className="  pb-5 font-medium mr-2 text-xl  rounded !h-9 !text-white !bg-button border !border-button  "

                        onClick={() => handleClickDeletePicking(item)}
                    >
                        <div className='flex' >
                            <i className='bx bx-trash text-xl'></i>
                            <span className='ml-2 text-base font-semibold' >Delete</span>
                        </div>

                    </Button>
                );
            }
        },
    ];

    const columnsProductPicking = [
        {
            title: 'Article no.',
            dataIndex: ["product", "article_no"],
            key: 'article_no',
            align: 'center' as AlignType,
        },
        {
            title: 'EAN code',
            dataIndex: ["product", "ean_code"],
            key: 'ean_code',
            align: 'center' as AlignType,
        },
        {
            title: 'Product name',
            dataIndex: "product_name",
            key: 'product_name',
            align: 'center' as AlignType,
            render: (id: string, item: TypeDataProductPicking) => {
                return (
                    <div className='  '>
                        <span className=" ">{item.product?.producer}/{item.product?.name}</span>
                    </div>
                )
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center' as AlignType,
            render: (_: any, item: TypeDataProductPicking) => {
                return (
                    <div className=' w-[40px] h-[40px] flex items-center justify-center border-[1px] border-[#888] mx-auto '>
                        <span className=" ">{item.quantity}</span>
                    </div>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as AlignType,
            render: (_: any, item: TypeDataProductPicking) => {
                return (
                    <div className={`${item.status === "Finished" ?
                        'text-green font-semibold'
                        : item.status === "Inprogress" || item.status === "Deleted" ?
                            "text-red font-semibold" : ''}`}>
                        {item.status}
                    </div>
                )
            }
        },
        {
            title: 'List of storage:',
            dataIndex: 'list_of_storage',
            key: 'list_of_storage',
            align: 'center' as AlignType,
            render: (id: string, item: TypeDataProductPicking) => {
                return (
                    <>
                        {item.listOfStorage?.map((item: ListOfStorageItem, index: number) => {
                            return (
                                <div key={index}>
                                    <p className='m-0' >{item?.storage_place}</p>
                                </div>
                            )
                        })}
                    </>
                );
            }
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center' as AlignType,
            render: (article_no: string, item: TypeDataProductPicking) => {
                return (
                    <Button
                        className="  pb-5 font-medium mr-2 text-xl rounded-lg !bg-button !h-9 !text-white  "
                        onClick={() => handleClickDeletePickingProduct(item)}
                    >
                        <div className='flex' >
                            <i className='bx bx-trash text-xl'></i>
                            <span className='ml-2 text-base font-semibold' >Delete</span>
                        </div>

                    </Button>
                );
            }
        },
    ];

    return (
        <>
            {isSpin ?
                <div className='w-full h-full flex justify-center items-center'>
                    <Spin size="large" spinning={isSpin} />
                </div>
                :
                <div className='px-4 pt-[4rem]'>
                    <h1 className='text-2xl mb-5'>Picking</h1>
                    <div className='px-10 w-[65%] 2xl:w-full'>
                        <div>
                            <div>
                                <div className='mb-12 flex justify-between  flex-start'>
                                    <Button
                                        className="  pb-5 font-medium mr-2 text-xl rounded !h-9 !text-white !bg-button border !border-button  "
                                        onClick={handleCreateManual}
                                    // disabled={isCreated}
                                    >
                                        <div className='flex items px-10' >
                                            <i className='bx bx-plus text-xl'></i>
                                            <span className='ml-2  text-base font-semibold leading-5 ' >Create manually</span>
                                        </div>
                                    </Button>

                                    <Button
                                        className="  pb-5 mx-2 font-medium  text-xl rounded !h-9 !text-white !bg-button border !border-button "

                                        onClick={handleShowUpLoad}
                                    >
                                        <div className='flex items px-10' >
                                            <i className='bx bx-upload text-2xl'></i>
                                            <span className='ml-2 text-base font-semibold leading-5 ' >Load product list</span>
                                        </div>
                                    </Button>

                                    <Button
                                        className="  pb-5 ml-2 font-medium  text-xl rounded !h-9 !text-white !bg-button border !border-button"

                                        onClick={handleDowload}
                                    >
                                        <div className='flex items px-10' >
                                            <i className='bx bx-download text-2xl'></i>
                                            <span className='ml-2 text-base font-semibold leading-5 ' >Download sample file</span>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {
                            isCreated ?
                                <>
                                    <Form
                                        name="dynamic_form_nest_item"
                                        onFinish={onFinish}
                                        style={{ width: "100%" }}
                                        autoComplete="off"
                                        form={form}
                                    >
                                        <Form.List
                                            name="products"
                                            initialValue={productsSelectInit}
                                        >
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <div key={key}>
                                                            <Space key={key} style={{ display: 'flex', }} align="baseline">
                                                                <h1 className="w-[1.5rem]">{index + 1} .</h1>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Article no:"
                                                                    name={[name, 'article_no']}
                                                                >
                                                                    <Select
                                                                        labelInValue
                                                                        showSearch
                                                                        placeholder="Article no"
                                                                        defaultValue={{ value: '0', label: 'Select article no' }}
                                                                        style={{ minWidth: '200px' }}
                                                                        onChange={e => handleChangeFormItem(e.value, index)}
                                                                        filterOption={(input: string, option: any) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        options={articleProducts}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="EAN code:"
                                                                    name={[name, 'ean_code']}
                                                                >
                                                                    <Select
                                                                        labelInValue
                                                                        showSearch
                                                                        placeholder="Article no"
                                                                        defaultValue={{ value: '0', label: 'Select ean no' }}
                                                                        style={{ minWidth: '200px' }}
                                                                        onChange={e => handleChangeFormItem(e.value, index)}
                                                                        filterOption={(input: string, option: any) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        options={eanProducts}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Quantity:"
                                                                    name={[name, 'quantity']}
                                                                >
                                                                    <InputNumber min={1} defaultValue={1} onChange={e => handleChangeQuantity(e, index)} />
                                                                </Form.Item>
                                                                <MinusCircleOutlined onClick={() => removeRow(index)} />
                                                            </Space>
                                                        </div>
                                                    ))}
                                                    <div className='flex justify-between w-[47%]'>
                                                        <Form.Item>
                                                            <Button className="  pb-5 font-medium  text-xl rounded !h-9 !text-white !bg-button border !border-button   "
                                                                onClick={() => addRow()} >
                                                                <div className='flex items px-12 ' >
                                                                    <i className='bx bx-plus text-xl'></i>
                                                                    <span className='ml-2  text-base font-semibold leading-5 ' >Add product</span>
                                                                </div>
                                                            </Button>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button className={` pb-5 font-medium  text-xl rounded !h-9 !text-white !bg-button border !border-button ${disable ? 'opacity-50' : 'opacity-100'}`}
                                                                htmlType="submit"
                                                                disabled={disable}>
                                                                <div className='flex items px-4' >
                                                                    <i className='bx bx-save text-xl'></i>
                                                                    <span className='ml-2  text-base font-semibold leading-5 ' >Save</span>
                                                                </div>
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form>
                                </>
                                :
                                <>
                                </>
                        }
                        <Spin size="large" spinning={isSpin} />
                        <div className="mb-5  ">
                            <div className=''>
                                <Table className=''
                                    dataSource={listPicking} columns={columnsProduct} loading={isSpin}
                                    pagination={{
                                        pageSizeOptions: ["10", "20", "30"],
                                        showSizeChanger: true
                                    }}
                                    bordered />
                            </div>
                        </div>
                    </div>

                    <div className="px-10 w-[65%] 2xl:w-full">
                        {proDataOpen ?
                            <div>
                                <h1 className="text-2xl mb-6">Product data</h1>
                                <p className='text-base font-medium'> Code of picking: <span className='font-normal'>{productData.code}</span></p>
                                <p className='text-base font-medium'> Date of creation: <span className='font-normal'>{productData.created_at}</span></p>
                                <p className='text-base font-medium mb-2'> Status:
                                    <span className={`ml-1 font-semibold ${productData.status === 'Deleted' ? 'text-red' : productData.status === 'Finished' ? 'text-green' : ''}`}>
                                        {productData.status === "Finished" ? "Completed" : productData.status}
                                    </span></p>
                                <div className="mb-10 mt-4 ">
                                    <div className=' border-black  '>
                                        <Table dataSource={productFilter} columns={columnsProductPicking} bordered
                                            pagination={{ pageSizeOptions: ["10", "20", "30"], showSizeChanger: true }}
                                        />
                                    </div>

                                </div>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                    <PopupDeletePicking
                        handleCancelDelete={handleCancelDelete}
                        handleOKDelete={handleOKDelete}
                        isModalVisibleDelete={isModalVisibleDelete}
                        dataItem={dataItemDelete}
                        title="DELETE PICKING"
                    />

                    <PopupDeletePickingProduct
                        handleCancelDelete={handleCancelDelete}
                        handleOKDelete={handleOKDeletePickingProduct}
                        isModalVisibleDelete={isModalVisibleDeletePickingProduct}
                        dataItem={dataItemDelete}
                        title="DELETE PICKING PRODUCT"
                    />
                </div >
            }
        </>
    )
}

export default Picking


