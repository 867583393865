import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Input, message, Spin, Table } from 'antd'
import { getPlan, getProductAllInventory, loadListProduct, profileLogin } from '../../service/auth/AuthService';
import PopupUpLoad from '../manage-product/PopupUpLoad';

const InventoryManagement = () => {
    
    const navigate = useNavigate()
    const [totalNum, setTotalNum] = useState<number>(0);
    const [totalStoredProduct, setTotalStoredProduct] = useState<number>(0);
    const [isSpin, setIsSpin] = useState(true);
    const [dataFile, setDataFile] = useState<any>()
    const [filterSearch, setFilterSearch] = useState<string>('')
    const [isModalUpLoad, setIsModalUpLoad] = useState(false);
    const [productFilter, setProductFilter] = useState<any>([])
    const getListData = async () => {

        try {
            const user = await profileLogin()
            const lastData = user.data?.results?.warehu
            const dataView = await getPlan(lastData?.uuid);
            setTotalStoredProduct(user.data?.results?.total_store_products);
            setIsSpin(false);
            const arr = dataView.data.results.reduce((target: any, item: any) => {
                target = target.concat(item.list_row_by_zone);
                return target
            }, [])
            const total = arr.reduce((t: any, item: any) => {
                t += item.des.reduce((sum: any, d: any) => {
                    return sum += d.length
                }, 0)
                return t
            }, 0)
            setTotalNum(total)

        } catch (error) { setIsSpin(false); }
    }
    // show total storage places

    useEffect(() => {
        getListData();

    }, [])
    const onClickHandler = () => {
        navigate('/inventory-management/manage-product');
    }
    // download
    const handleDowload = async () => {
        fetch('https://warehudev-api.maxline.hu/api/download-sample-file')
            .then(response => {
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.click();
                });
                //window.location.href = response.url;
            });
    }
    // modal up load file
    const handleShowUpLoad = async () => {

        setIsModalUpLoad(true)
    }
    const handleCancelUpLoad = () => {
        setIsModalUpLoad(false)
    }
    const handleOkUpLoad = async () => {
        const res = await loadListProduct({ csv_file: dataFile[0] });
        message.success(res.data.message);
        setIsModalUpLoad(false)
    }
    //  Search
    // click search
    const handleClickSearch = async () => {
        setIsSpin(true);
        try {
            const res = await getProductAllInventory(filterSearch);
            const results = res.data.results;
            const dataArray = [];
            dataArray.push({ article_no: results?.article_no, ean_code: results?.ean_code, producer: results?.producer, name: results?.name })
            setProductFilter(dataArray);
            setIsSpin(false);

        } catch (error) {
            message.error('Not found')
            setIsSpin(false);
        }
    }
    // 
    // enter
    const handleEnterSearch = (event: any) => {
        if (event.key === "Enter") {
            handleClickSearch()
        }
    }
    const handleClickMovement = () => {
        navigate('/inventory-management/product-movement');
    }
    const dataSource = [
        {
            key: '1',
            storage_place: 'A1-02-04',
            operation: 'Removal',
            modification: '2020.01.27.17:14:33',
        },
        {
            key: '2',
            storage_place: 'A1-02-05',
            operation: 'Storage',
            modification: '2020.01.30.11:28:10',
        },

    ];

    const columnsInventory = [
        {
            title: 'Storage place',
            dataIndex: 'storage_place',
            key: 'storage_place',
            className: "text-center font-semibold "
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            className: "text-center "
        },
        {
            title: 'Last modification ',
            dataIndex: 'modification',
            key: 'modification',
            className: "text-center "
        },
        {
            title: 'Remark ',
            dataIndex: 'remark',
            key: 'remark',
            className: "text-center "
        },

    ];
    return (
        <>
            {isSpin ?
                <div className='w-full h-full flex justify-center items-center'>
                    <Spin size="large" spinning={isSpin} />

                </div>
                :
                <div className='px-8 pt-8'>
                    <h1 className='text-2xl mb-5'>Inventory Management</h1>
                    <div className='px-10 w-[60%] 2xl:w-full'>
                        <div className='flex items-center mb-10 justify-between'>
                            <h1 className=' text-xl w-[15rem]'>Product search</h1>
                            <div className=' w-full  mx-4 py-2'>
                                <Input className='h-[36px]' onChange={(e) => setFilterSearch(e.target.value)} onKeyDown={handleEnterSearch} />
                            </div>
                            <Button
                                className="  pb-5 font-medium min-w-[8rem] text-xl !bg-button !border-button !text-white rounded !h-9 "
                                onClick={handleClickSearch}
                            >
                                <div className='flex items-center ' >
                                    <i className='bx bx-search text-xl'></i>
                                    <span className='ml-2 text-base font-semibold leading-5 ' >Search</span>
                                </div>
                            </Button>
                        </div>
                        <div className='mb-20 flex justify-between  flex-start'>
                            <Button
                                className="  pb-5 mr-2 font-medium min-w-[6rem] text-xl  !bg-button !border-button !text-white rounded !h-9  "
                                onClick={onClickHandler}
                            >
                                <div className='flex items px-10' >
                                    <i className='bx bx-plus text-xl'></i>
                                    <span className='ml-2  text-base font-semibold leading-5 ' >Add product</span>
                                </div>
                            </Button>
                            <Button
                                className="  pb-5 mx-2 font-medium min-w-[6rem] text-xl !bg-button !border-button !text-white rounded !h-9 "                       
                                onClick={handleShowUpLoad}
                            >
                                <div className='flex items px-10' >
                                    <i className='bx bx-upload text-2xl'></i>
                                    <span className='ml-2 text-base font-semibold leading-5 ' >Load product list</span>
                                </div>
                            </Button>
                            <Button
                                className="  pb-5 ml-2 font-medium min-w-[6rem] text-xl !bg-button !border-button !text-white rounded !h-9"
                                onClick={handleDowload}
                            >
                                <div className='flex items px-10' >
                                    <i className='bx bx-download text-2xl'></i>
                                    <span className='ml-2 text-base font-semibold leading-5 ' >Download sample file</span>
                                </div>
                            </Button>

                        </div>
                        <Spin size="large" spinning={isSpin}>
                            {productFilter.length > 0 ?
                                <div>

                                    <div>
                                        <div className='flex w-full'>
                                            <div className='w-2/5 mr-4'>
                                                <h1 className='text-2xl mb-4'>Product data</h1>
                                                {productFilter?.map((item: any, index: number) => {
                                                    return (
                                                        <div key={index} >
                                                            <div className="flex items-center w-full">
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Article no.</p>
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{item.article_no}</p>
                                                            </div>
                                                            <div className="flex items-center w-full">
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>EAN code:</p>
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{item?.ean_code}</p>
                                                            </div>
                                                            <div className="flex items-center w-full">
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Producer:</p>
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{item?.producer}</p>
                                                            </div>
                                                            <div className="flex items-center w-full">
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black'>Type:</p>
                                                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black ml-[-1px]'>{item?.name}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='w-3/5 ml-4'>
                                                <h1 className='text-2xl mb-4'>Inventory data</h1>
                                                <div className='border-t-[1px] border-black border-l-[1px]'>
                                                    <Table dataSource={dataSource} columns={columnsInventory} pagination={false} />
                                                </div>

                                            </div>

                                        </div>
                                        <div className=' w-full flex flex-start '>
                                            <div className='my-10 flex justify-between w-full  flex-start'>
                                                <Button
                                                    className="  pb-5 mr-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9  "
                                                    onClick={handleClickMovement}
                                                >
                                                    <div className='flex items px-4' >
                                                        <i className='bx bx-transfer-alt text-2xl'></i>
                                                        <span className='ml-2  text-base font-semibold leading-5 ' >Product movement</span>
                                                    </div>
                                                </Button>
                                                <Button
                                                    className="  pb-5 mx-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9  "

                                                >
                                                    <div className='flex items px-4' >
                                                        <i className='bx bx-x text-2xl'></i>
                                                        <span className='ml-2  text-base font-semibold leading-5 ' >Inactivate product</span>
                                                    </div>
                                                </Button>
                                                <Button
                                                    className="  pb-5 mx-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9 "
                                                >
                                                    <div className='flex items px-4' >
                                                        <i className='bx bx-edit text-2xl'></i>
                                                        <span className='ml-2 text-base font-semibold leading-5 ' >Modify product</span>
                                                    </div>
                                                </Button>
                                                <Button
                                                    className="  pb-5 ml-2 font-medium text-xl !bg-button !border-button !text-white rounded !h-9"
                                                >
                                                    <div className='flex items px-4' >
                                                        <i className='bx bxs-trash text-2xl'></i>
                                                        <span className='ml-2 text-base font-semibold leading-5 ' >Delete product</span>
                                                    </div>
                                                </Button>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                :
                                <div className='flex w-full'>
                                    <p className='w-1/2 p-4 border-[1px] border-r-[0px] border-black font-semibold'>
                                        Number of recorded products: <span className='ml-10 text-red'>{totalNum}</span>
                                    </p>
                                    <p className='w-1/2 p-4 border-[1px] border-black font-semibold'>
                                        Number of stored products: <span className='ml-10'> {totalStoredProduct}</span>
                                    </p>
                                </div>
                            }
                        </Spin>



                    </div>

                </div>}
            <PopupUpLoad
                handleOkUpLoad={handleOkUpLoad}
                handleCancelUpLoad={handleCancelUpLoad}
                isModalUpLoad={isModalUpLoad}
                setDataFile={setDataFile}

            />
        </>

    )
}

export default InventoryManagement
