import React, { useEffect, useState } from 'react'
import { Table, Spin, Button, } from 'antd'
import { AlignType } from 'rc-table/lib/interface'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getProductAllInventory } from '../../service/auth/AuthService';
const StorageDataSheet = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const article_no = searchParams.get('article_no');
    const ean_code = searchParams.get('ean_code');
    const search = searchParams.get('search');
    const [isSpin, setIsSpin] = useState(false);
    const [dataSource, setDataSource] = useState<any>([])
    const [dataSend, setDataSend] = useState<any>([]);

    const navigate = useNavigate()
    const params = useLocation();

    const columns = [
        {
            title: 'Article no',
            dataIndex: 'article_no',
            key: 'article_no',
            align: 'center' as AlignType,
        },

        {
            title: 'Ean code',
            dataIndex: 'ean_code',
            key: 'ean_code',
            align: 'center' as AlignType,
        },
        {
            title: 'Producer',
            dataIndex: 'producer',
            key: 'producer',
            align: 'center' as AlignType,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center' as AlignType,
        },
    ];

    const getProduct = async () => {
        setIsSpin(true);
        try {
            if (!article_no && !ean_code && !search) {
                setDataSource([]);
            } else {
                let filterSearch = "";
                if (article_no) { filterSearch = article_no }
                else if (ean_code) { filterSearch = ean_code || '' }
                else {
                    filterSearch = search || "";
                }
                const res = await getProductAllInventory(filterSearch);
                const results = res.data.results;
                setDataSend(results);
                setDataSource(results?.products.filter((item: any) => item?.quantity > 0 && item?.status !== 'inactive'));
            }
            setIsSpin(false);
        } catch (error) {
            setDataSource([]);
            setIsSpin(false);
        }
    }
    
    useEffect(() => {
        getProduct();
    }, [])
    const handleClickStorageRemoval = () => {
        navigate('/storage-removal', { state: { item: params?.search.split("=")[1] } });
    }

    return (
        <>
        {isSpin ?
        <div className='w-full h-full flex justify-center items-center'>
					<Spin size="large" spinning={isSpin} />

				</div>
				:
            <div className="px-4 pt-[4rem] ">
                <div className='w-[80%]'>
                    <h1 className="w-1/2 text-2xl mb-6">Storage data sheet</h1>
                    <div className='w-full mb-8'>
                        <h1 className='text-xl mb-4'>Storage data</h1>
                        {

                            <div className="flex items-center w-full">
                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2 text-base font-semibold border-black border-r-0'>Storage place: </p>
                                <p className='w-1/2 border-[1px] m-0 mb-[-1px] p-2  text-base font-semibold border-black ml-[-1px]'>{params.search.split("=")[1]}</p>
                            </div>
                        }


                    </div>
                </div>

                <div className='w-[80%] mt-4'>
                    <div className=' border-black  '>
                        <Table dataSource={dataSource} columns={columns} bordered 
                        pagination={{ pageSizeOptions: ["10", "20", "30"], showSizeChanger: true }}
                        />
                    </div>
                
                    <div className="mt-4">
                        <Button
                            className="  pb-5 mr-2 font-medium text-xl rounded !bg-button border !border-button !text-white !h-9 "
                            onClick={handleClickStorageRemoval}
                        >
                            <div className='flex items px-4' >
                                <i className='bx bx-plus text-2xl'></i>
                                <span className='ml-2  text-base font-semibold leading-5 ' > Add product</span>
                            </div>
                        </Button>

                    </div>
                </div>
            </div>
        }
        </>

    )
}

export default StorageDataSheet
