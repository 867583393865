import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { dataTopMenu } from '../../data/dataMenu';
import { deleteAccessToken } from '../../helper/tokenHelper';
import PopUpLogOut from '../../pages/popup-logout/PopUpLogOut';
import { profileLogin } from '../../service/auth/AuthService';
import { TypeMenuItem, TypeSub, TypeSubMenu } from '../../interface/manage.interface';

const TopMenu = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const [isModalVisibleLogout, setIsModalVisibleLogout] = useState(false);
	const [inforUser, setInforUser] = useState<any>();
	const dataChild = dataTopMenu.filter(item => item.subMenu) || [];
	
	const count = pathname.match(/\//g) || [];
	const handleCancel = () => {
		setIsModalVisibleLogout(false);
	};
	const handleOK = async () => {
		navigate('/authenticate');
		deleteAccessToken();
		setIsModalVisibleLogout(false);
	};
	const handlePopupLogout = () => {
		setIsModalVisibleLogout(true);
	};

	useEffect(() => {
		getInforUser();
	}, []);
	const getInforUser = async() => {
		try {
			const res = await profileLogin()
			setInforUser(res.data.results);
		} catch (error) {
			console.log(error);
		}
		
	};
	
	return (
		<>
			<div className="h-[52px] w-full bg-topMenu px-4 shadow-md">
				<div className="flex justify-between items-center h-full">
					{count.length === 1 ?
						<>
							{dataTopMenu.map((item: TypeMenuItem, index: number) => {
								if (item.link === pathname) {
									return (<div key={index}>
										<div className="flex items-center h-full text-logo">
											<i className={item.icon}></i>
											<i className="bx bx-chevrons-right text-xl mx-2"></i>
											<span>Warehu - {item.name}</span>
										</div>
									</div>)
								}
							})}
						</>
						:
						<>
							{count.length === 2 ?
								<>
									{dataChild[0].subMenu?.map((item: TypeSubMenu, ind: number) => {
										if (item.link === pathname) {
											return (
												<div key={ind}>
													<div className="flex items-center h-full text-logo">
														<i className={item.icon}></i>
														<i className="bx bx-chevrons-right text-xl mx-2"></i>
														<span>Warehu - {dataChild[0].name}</span>
														<span className='text-base mx-2'>{'>'}</span>
														<span>{item.title}</span>
													</div>

												</div>
											)
										}
									})}
								</>
								:
								<>
									{dataChild[0].subMenu?.map((item: TypeSubMenu, ind: number) => {
										if (item.link === pathname) {
											return (
												<div key={ind}>
													<div className="flex items-center h-full text-logo">
														<i className={item.icon}></i>
														<i className="bx bx-chevrons-right text-xl mx-2"></i>
														<span>Warehu - {dataChild[0].name}</span>
														<span className='text-base mx-2'>{'>'}</span>
														<span>{item.title}</span>
														<div>
															{item.sub.map((sub_item: TypeSub, sub_index: number) => {
																return (
																	<div key={sub_index}>
																		<span className='text-base mx-2'>{'>'}</span>
																		<span>{sub_item.titleChild}</span>
																	</div>
																)
															})}
														</div>
													</div>
												</div>
											)
										} else {
											return (
												<div key={ind}>
													<div className="flex items-center h-full text-logo">
														<div>
															{item.sub?.map((sub_item: TypeSub, sub_index: number) => {
																if (sub_item.linkChild === pathname) {
																	return (
																		<div key={sub_index} className='flex items-center'>
																			<i className={item.icon}></i>
																			<i className="bx bx-chevrons-right text-xl mx-2"></i>
																			<span>Warehu - {dataChild[0].name}</span>
																			<span className='text-base mx-2'>{'>'}</span>
																			<span>{item.title}</span>
																			<span className='text-base mx-2'>{'>'}</span>
																			<span>{sub_item.titleChild}</span>
																		</div>
																	)
																}
															})}
														</div>

													</div>

												</div>
											)
										}
									})}
								</>}
						</>}



					<div className="flex items-center  h-full text-logo ">
						<div className=" flex flex-col items-end mr-4 ">
							<span className="text-black text-xs font-semibold">Logged in as:</span>
							<span className="font-semibold">{inforUser?.email}</span>
						</div>
						<div
							className="h-[1.5rem] w-[1.5rem] bg-white rounded-full flex items-center justify-center cursor-pointer "
							
						>
							<i className="bx bx-user text-base "></i>
						</div>
						<div
							className="h-[1.5rem] w-[1.5rem] bg-white rounded-full flex items-center justify-center ml-1 cursor-pointer"
							
							onClick={() => handlePopupLogout()}
						>
							<i className="bx bx-exit text-base  "></i>
						</div>
					</div>
				</div>
			</div>
			<PopUpLogOut ModalVisible={isModalVisibleLogout} handleCancel={handleCancel} handleOK={handleOK} />
		</>
	);
};

export default TopMenu;
