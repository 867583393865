import { Button, message, Modal, Upload, UploadProps } from 'antd';
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
const props: UploadProps = {
    name: 'csv_file',
    action: 'http://warehudev-api.maxline.hu/api/upload',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};
const PopupUpLoad = (props: any) => {
    const { isModalUpLoad, handleCancelUpLoad, handleOkUpLoad, setDataFile } = props;
    return (
        <div>
            <Modal
                destroyOnClose={true}
                visible={isModalUpLoad}
                onCancel={handleCancelUpLoad}
                onOk={handleOkUpLoad}
                className="round-sm text-center"
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { minWidth: '7rem', backgroundColor: '#468da5', borderColor: '#468da5', color: '#fff', borderRadius: '4px', height: '36px' } }}
                centered
                title='UPLOAD FILES'
            >
                <Upload {...props}
                    beforeUpload={csv_file => {
                        const data = [];
                        data.push(csv_file);
                        setDataFile(data);
                        return false;
                    }}>
                    <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                </Upload>
            </Modal>
        </div>
    );
};

export default PopupUpLoad;