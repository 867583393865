import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Form, InputNumber, message, Select, Spin, } from 'antd'
// import PopupProductGroup from './PopupProductGroup';
import { profileLogin, getPlan, productMovementInventory, addProductToInventory, getProductAll } from '../../service/auth/AuthService';
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 32 },
};

const StorageRemoval = () => {
    const [isSpin, setIsSpin] = useState(true);
    const { state } = useLocation() as any;
    const heightButton = '40px';
    const [storagePlaces, setStoragePlaces] = useState<any>([]);
    const [products, setProducts] = useState<any>();
    const [form] = Form.useForm();
    const blockInvalidChar = (e: any) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
    const [disable, setDisable] = useState(true)
    const [storageName, setStorageName] = useState('')

    const onFinish = async (type: any) => {
        let values = form.getFieldsValue();
        values = {
            ...values, operation: type, article_no_ean_code: values.article_no_ean_code?.value === undefined ? state?.item.products[0].ean_code : values.article_no_ean_code?.value,
            storage_place: values.storage_place?.value === undefined ? state?.item.storage : values.storage_place?.value,
        }
        setIsSpin(true);
        try {
            let res;
            if (type == "Storage") {
                res = await addProductToInventory(values);
                message.success(res.data.message)
                setIsSpin(false);
            } else {
                res = await productMovementInventory(values);
                message.success(res.data.message)
                setIsSpin(false);
            }
            form.resetFields();
            setDisable(true);
        } catch (error: any) {
            message.error(error.response.data.message)
            setIsSpin(false);
        }
    }

    const onValueChange = async () => {
        const values = form.getFieldsValue();
        setStorageName(values.storage_place?.value)
        if (values.storage_place?.value == '0' || values.article_no_ean_code.value == 0 || values.quantity == null) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }
    const onFinishStorage = () => {
        onFinish("Storage");
    }

    const onFinishRemoval = () => {
        onFinish("Removal");
    }

    useEffect(() => {
        getListData()
    }, [])

    const getListData = async () => {
        setIsSpin(true)
        try {
            const user = await profileLogin()
            const lastData = user.data?.results?.warehu
            const plan = await getPlan(lastData?.uuid);

            let storagePlaces: any[] = []
            plan.data.results[0]?.list_row_by_zone.forEach((item: any) => {
                item.des.forEach((des: any) => {
                    des.forEach((_des: any) => {
                        storagePlaces = [...storagePlaces, _des]
                    })
                })
            })
            const dataStorages = storagePlaces.map((item: any) => {
                return (
                    {
                        value: item.name,
                        label: item.name,
                    }
                )
            }
            )
            setStoragePlaces(dataStorages);
            const res = await getProductAll(1, 999999999999)
            // const res = await getListProductAll()
            if (res.data.results.data?.length > 1) {
                setDisable(true);
            }
            const data = res.data.results.data?.map((item: any) => (
                {
                    value: item.article_no,
                    label: item.article_no + " / " + item.ean_code
                }
            ))
            setProducts(data)
            setIsSpin(false)
        } catch (error) { 
            setIsSpin(false)
        }
    }
    return (
        <>
            {isSpin ?
                <div className='w-full h-full flex justify-center items-center'>
                    <Spin size="large" spinning={isSpin} />

                </div>
                :

                <Spin size="large" spinning={isSpin} >
                    <div className='px-4 pt-[4rem]'>
                        <h1 className='text-2xl mb-5'>Storage/ Removal</h1>
                        <div className='w-[100%] 2xl:w-full mt-10'>
                            <div className="mb-10 w-[90%] ">
                                <Form autoComplete="off" form={form} {...layout}
                                    initialValues={{
                                        storage_place: {
                                            value: state === null ? '0' : state.item,
                                            label: state === null ? 'Select storage place' : state.item
                                        },
                                        article_no_ean_code: {
                                            value: '0',
                                            label: 'Select Article no./EAN'
                                        }, 
                                        quantity:1
                                    }}  >
                                    <Form.Item name="storage_place" label="Storage place" className="font-semibold" >
                                        <Select
                                            labelInValue
                                            showSearch
                                            placeholder="Select storage place"
                                            style={{ minWidth: '100px' }}
                                            onChange={onValueChange}
                                            options={storagePlaces}
                                        />
                                    </Form.Item>
                                    <Form.Item name="article_no_ean_code" label="Article no./EAN" className="font-semibold" >
                                        <Select
                                            labelInValue
                                            showSearch
                                            placeholder="Select Article no./EAN"
                                            style={{ minWidth: '100px' }}
                                            onChange={onValueChange}
                                            options={products}
                                        />
                                    </Form.Item>
                                    <div className='w-full'>
                                        <Form.Item name="quantity" label="Quantity" className="font-semibold" >
                                            <InputNumber style={{ width: '100%' }} onChange={onValueChange} min={1} type='number' onKeyDown={blockInvalidChar} />
                                        </Form.Item>
                                    </div>
                                    <div className='flex items-center ml-[10%]'>
                                        <Form.Item className=" rounded-lg " style={{ marginTop: '2rem' }}>
                                            <Button
                                                className="  pb-5 font-medium min-w-[6rem]  text-xl rounded-lg ml-[12%]"
                                                style={{
                                                    backgroundColor: "#468da5", borderColor: "#468da5", color: '#fff', borderRadius: '4px', height: `${heightButton}`,
                                                    opacity: `${disable ? '0.5' : '1'}`
                                                }}
                                                htmlType="button"
                                                onClick={onFinishStorage}
                                                disabled={disable}
                                            >
                                                <div className='flex  px-4' >
                                                    <i className='bx bx-cart text-2xl'></i>
                                                    <i className='bx bx-right-arrow-alt text-2xl'></i>
                                                    <span className='ml-2 text-base font-semibold leading-5 ' >Storage</span>
                                                </div>
                                            </Button>

                                        </Form.Item>
                                        <Form.Item wrapperCol={{ offset: 32, span: 32 }} className=" rounded-lg ml-2" style={{ marginTop: '2rem', marginLeft: '1.5rem' }}>
                                            <Button
                                                className="  pb-5 font-medium min-w-[6rem] text-xl rounded-lg ml-4"
                                                style={{
                                                    backgroundColor: "#F08080", borderColor: "#F08080", color: '#fff', borderRadius: '4px', height: `${heightButton}`,
                                                    opacity: `${disable ? '0.5' : '1'}`
                                                }}
                                                htmlType="submit"
                                                onClick={onFinishRemoval}
                                                disabled={disable}
                                            >
                                                <div className='flex  px-4' >
                                                    <i className='bx bx-cart text-2xl'></i>
                                                    <i className='bx bx-left-arrow-alt text-2xl'></i>
                                                    <span className='ml-2 text-base font-semibold leading-5 ' >Removal</span>
                                                </div>
                                            </Button>
                                        </Form.Item>
                                    </div>


                                </Form>

                            </div>
                        </div>
                    </div >
                </Spin >
            }
        </>

    )
}

export default StorageRemoval
