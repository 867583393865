import { Modal } from 'antd';
import React from 'react'

const PopupDeletePicking = (props: any) => {
    const { isModalVisibleDelete, handleCancelDelete, handleOKDelete, dataItem, title } = props;
    return (
        <div>
            <Modal
                destroyOnClose={true}
                visible={isModalVisibleDelete}
                onCancel={handleCancelDelete}
                onOk={handleOKDelete}
                className="round-sm text-center "
                centered
                title={title}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { minWidth: '7rem', backgroundColor: '#468da5', borderColor: '#468da5', fontWeight: '500' } }}

            >
                <div className="flex flex-col justify-center">
                    <div className="mb-2 text-base ">
                        <p className="text-red"> {`* Do you want delete : ${dataItem?.code}!!!`}</p>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default PopupDeletePicking
