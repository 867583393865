import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import MainLayOut from './pages/layout/MainLayOut';
import PrivateRoute from './routes/PrivateRoute';
import './assets/boxicons-2.1.1/css/boxicons.min.css';
import './App.css';
import 'antd/dist/antd.min.css';
import './pages/scss/style.scss';

import NotFountPage from './pages/404';
import Authenticate from './pages/authenticate/Authenticate';
import ForgotPass from './pages/forgot-password/ForgotPass';
import Register from './pages/register/Register';
import Home from './pages/home/Home';
import WarehousePlanning from './pages/warehouse-planning/WarehousePlanning';
import StorageLabelPrinting from './pages/storage-label-printing/StorageLabelPrinting';
import Picking from './pages/picking/Picking';
import TransferList from './pages/transfer-list/TransferList';
import ProductReceiving from './pages/product-receiving/ProductReceiving';
import InventoryManagement from './pages/inventory-management/InventoryManagement';
import ProductMovement from './pages/product-movement/ProductMovement';
import ProductDataSheet from './pages/product-data-sheet/ProductDataSheet';
import ProductData from './pages/product-data/ProductData';
import ManageProduct from './pages/manage-product/ManageProduct';
import StorageDataSheet from './pages/storage-data-sheet/StorageDataSheet';
import StorageRemoval from './pages/storage-removal/StorageRemoval';
import ManageProductGroups from './pages/manage-product-groups/ManageProductGroups';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Navigate to="/authenticate" />} />
				<Route path="/authenticate" element={<Authenticate />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forgot-password" element={<ForgotPass />} />
				<Route
					path="/"
					element={
						<PrivateRoute>
							<MainLayOut />
						</PrivateRoute>
					}
				>
					<Route path="home" element={<Home />} />
					<Route path="warehouse-planning" element={<WarehousePlanning />} />
					<Route path="storage-label-printing" element={<StorageLabelPrinting />} />
					<Route path="inventory-management" element={<InventoryManagement />} />
					<Route path="inventory-management/product-data" element={<ProductData />} />
					<Route path="inventory-management/manage-product" element={<ManageProduct />} />
					<Route path="inventory-management/manage-product-groups" element={<ManageProductGroups />} />
					<Route path="inventory-management/manage-product/product-movement" element={<ProductMovement />} />
					<Route path="inventory-management/manage-product/product-data-sheet" element={<ProductDataSheet />} />
					<Route path="inventory-management/manage-product/storage-data-sheet" element={<StorageDataSheet />} />
					<Route path="storage-removal" element={<StorageRemoval />} />
					<Route path="/picking" element={<Picking />} />
					<Route path="/transfer-list" element={<TransferList />} />
					<Route path="/product-receiving" element={<ProductReceiving />} />
				</Route>
				<Route path="/*" element={<NotFountPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
