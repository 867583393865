export const sizePaper = [
    {
        title: 'A4',
        key: 1,
        value: 'A4'
    },
    {
        title: 'A6',
        key: 2,
        value: 'A6'
    },
    {
        title: 'A8',
        key: 3,
        value: 'A8'
    },
]