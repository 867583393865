import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate, } from 'react-router-dom';

const SideMenu = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [isOpen, setIsOpen] = useState(true);
	return (
		<div className="w-[220px]  flex flex-col bg-bodyMenu opacity-90"  >
			<div className="fixed top-0 left-0 right-0">
				<div
					className=" text-xl p-2 bg-logo font-semibold text-white cursor-pointer z-10 flex items-start"
					onClick={() => navigate('/home')}
				>
					<span className="text-3xl p-0">W</span>
					<span className="text-2xl">AREHU</span>
				</div>

			</div>
			<div className=" p-2 pt-[4rem] text-white ">
				<ul>
					<li className=" py-1 cursor-pointer mb-3" >
						<NavLink
							to={'/home'}
							className={` flex items-center   duration-300 text-white `}
						>
							<i className="bx bx-home text-xl font-semibold"></i>
							<span className="pl-1  text-base">Home</span>
						</NavLink>
					</li>
					<li className=" py-1 cursor-pointer mb-3 " >
						<NavLink
							to={'/warehouse-planning'}
							className={` flex items-center   duration-300 text-white `}
						>
							<i className="bx bx-palette text-xl"></i>
							<span className="pl-1  text-base">Warehouse planning</span>
						</NavLink>
					</li>
					<li className=" py-1 cursor-pointer mb-3 " >
						<NavLink
							to={'/storage-label-printing'}
							className={` flex items-center   duration-300 text-white `}
						>
							<i className="bx bx-folder text-xl"></i>
							<span className="pl-1  text-base">Storage label printing</span>
						</NavLink>
					</li>
					<ul className=" py-1  mb-3 " >
						<>
							<li className='flex items-center   duration-300 text-white'  >

								{/* <NavLink
									to={'#'}
									className={` flex items-center   duration-300 text-white `}
								> */}
								<i className="bx bx-data text-xl"></i>
								<span className="pl-1  text-base">Inventory management</span>

								{/* </NavLink> */}
							</li>

							<ul className={`sub-menu ml-6 mt-3 ${isOpen == true || pathname == '/inventory-management/manage-product' ||
								pathname == '/inventory-management/product-movement' ? 'visible' : "hidden"} `}>
								<li className='py-1 cursor-pointer mb-2'>
									<NavLink
										to={'/inventory-management/manage-product'}
										className={` flex items-center   duration-300 text-white  `}
									>
										<span className="pl-1  text-base">Manage product</span>
									</NavLink>
								</li>
								<li className='py-1 cursor-pointer mb-2'>
									<NavLink
										to={'/inventory-management/manage-product-groups'}
										className={` flex items-center duration-300 text-white `}
									>
										<span className="pl-1  text-base">Manage product group</span>
									</NavLink>
								</li>
								{/* <li className='py-1 cursor-pointer '>
									<NavLink
										to={'/inventory-management/storage-removal'}
										className={` flex items-center duration-300 text-white `}
									>
										<span className="pl-1  text-base">Storage/ Removal</span>
									</NavLink>
								</li> */}
							</ul>
						</>
					</ul>
					<li className=" py-1 cursor-pointer mb-3 " >
						<NavLink
							to={'/storage-removal'}
							className={` flex items-center   duration-300 text-white `}
						>
							<i className="bx bx-exit text-xl"></i>
							<span className="pl-1  text-base">Storage / Removal</span>
						</NavLink>
					</li>
					<li className=" py-1 cursor-pointer mb-3 " >
						<NavLink
							to={'/picking'}
							className={` flex items-center   duration-300 text-white `}
						>
							<i className="bx bx-check-square text-xl"></i>
							<span className="pl-1  text-base">Picking</span>
						</NavLink>
					</li>
					<li className=" py-1 cursor-pointer mb-3 " >
						<NavLink
							to={'/transfer-list'}
							className={` flex items-center   duration-300 text-white `}
						>
							<i className="bx bx-list-ul text-xl"></i>
							<span className="pl-1  text-base">Transfer list</span>
						</NavLink>
					</li>
					<li className=" py-1 cursor-pointer mb-3 " >
						<NavLink
							to={'/product-receiving'}
							className={` flex items-center   duration-300 text-white `}
						>
							<i className="bx bx-box text-xl"></i>
							<span className="pl-1  text-base">Product receiving</span>
						</NavLink>
					</li>
				</ul>

			</div>
		</div>
	);
};

export default SideMenu;
