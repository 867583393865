import { Modal } from 'antd';
import React from 'react';

const PopUpLogOut = (props: any) => {
	const { ModalVisible, handleCancel, handleOK } = props;
	return (
		<div>
			<Modal
				destroyOnClose={true}
				visible={ModalVisible}
				onCancel={handleCancel}
				onOk={handleOK}
				className="round-sm text-center"
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { minWidth: '7rem', backgroundColor: '#468da5', borderColor: '#468da5', color: '#fff', borderRadius: '4px', height: '36px' } }}
				centered
			>
				<p className="text-center text-red">Do you want logout this user?</p>
			</Modal>
		</div>
	);
};

export default PopUpLogOut;
