import React, { useState } from 'react';
import { Button,  Form, Input, message, Spin } from 'antd';
import { FolderOutlined, LockOutlined, PhoneOutlined, SnippetsOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// import { errorAuth } from '../../enum/auth/auth.error';
import { registerUser } from '../../service/auth/AuthService';
import { useNavigate } from 'react-router-dom';
import { Format_Email } from '../../constant/auth.constant';
import { TypeRegisterUser } from '../../interface/auth.interface';
import { errorRegister } from '../../enum/register.error';
import { Format_Phone } from '../../constant/register.constant';

export default function Register() {
	const [formRegister] = Form.useForm();
	const [usePassWord, setUsePassword] = useState<string>('');
	const [isSpin, setIsSpin] = useState(false);
	const navigate = useNavigate();

	const onFinish = (values: TypeRegisterUser) => {
		setIsSpin(true);
		registerUser(values)
			.then(res => {
				message.success(res.data.message);
				setIsSpin(false);
				formRegister.setFieldsValue({
					name: '',
					phone: '',
					email: '',
					password: '',
					password_confirmation: '',
				});
				navigate('/authenticate')
			})
			.catch(err => {
				setIsSpin(false);
				message.error(err.response.data.message);
			});

	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const handleChange = () => {
		const realPass = formRegister.getFieldValue('password');
		setUsePassword(realPass);
	};

	const handleGoBack = () => {
		navigate(-1);
	};
	return (
		<Spin size="small" spinning={isSpin} delay={1000}>
			<div className="bg_auth h-[100vh] flex items-center ">
				<div className="item_auth w-1/2 flex items-center m-auto h-[90%] rounded-lg overflow-hidden box shadow-lg shadow-gray-400 sm:w-3/4 sm:h-[80%] ">
					<div className="bg_left w-1/2 h-full lg:hidden"></div>
					<div className="w-1/2 h-full lg:w-full ">
						<Form
							name="basic"
							labelCol={{ span: 32 }}
							wrapperCol={{ span: 32 }}
							initialValues={{ remember: true }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
							layout="vertical"
							size="large"
							className="w-full h-full bg-white rounded my-auto flex items-center justify-center lg:flex lg:justify-center "
							form={formRegister}
						>
							<div className="p-5 ">
								<div className="mb-5 flex items-center justify-center relative">
									<div className=" flex items-center">
										<ArrowLeftOutlined
											className="mr-[1rem]"
											onClick={() => {
												handleGoBack();
											}}
										/>
									</div>
									<h1 className=" text-2xl font-sans text-center mb-0 sm:text-xl">
										Sign in account!
									</h1>
								</div>
								<p className="text-gray-400 text-center sm:text-xs">
									Registering an account is convenient for use, improving management efficiency.
								</p>
								<Form.Item
									className="item-form-input"
									name="name"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(new Error(errorRegister.NAME_NONE));
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input
										prefix={<SnippetsOutlined className="mr-2" />}
										placeholder="Please enter your name"
									/>
								</Form.Item>
								<Form.Item
									className="item-form-input"
									name="phone"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(new Error(errorRegister.PHONE_NONE));
												} else {
													return Promise.resolve();
												}
											},
										},
										{
											pattern: new RegExp(Format_Phone),
											message: errorRegister.PHONE_NUMBER,
										},
									]}
								>
									<Input
										prefix={<PhoneOutlined className="mr-2" />}
										placeholder="Please enter your phone"
										// type="number"
									/>
								</Form.Item>
								<Form.Item
									className="item-form-input"
									name="email"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(new Error(errorRegister.EMAIL_NONE));
												} else if (!Format_Email.test(val)) {
													return Promise.reject(new Error(errorRegister.EMAIL_FORMAT));
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input
										prefix={<FolderOutlined className="mr-2" />}
										placeholder="Please enter your email"
									/>
								</Form.Item>
								<Form.Item
									className="item-form-input"
									name="password"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(new Error(errorRegister.PASSWORD_NONE));
												} else if (val.length < 6) {
													return Promise.reject(new Error(errorRegister.PASSWORD_LENGTH));
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input.Password
										prefix={<LockOutlined className="mr-2" />}
										placeholder="Please enter your password"
										onChange={handleChange}
									/>
								</Form.Item>

								<Form.Item
									className="mb-10 item-form-input"
									name="password_confirmation"
									rules={[
										{
											validator(rule, val) {
												if (val === undefined || val === null || val === '') {
													return Promise.reject(
														new Error(errorRegister.PASSWORD_CONFIRM_NONE),
													);
												} else if (usePassWord !== val) {
													return Promise.reject(new Error(errorRegister.PASSWORD_INCORRECT));
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input.Password
										prefix={<LockOutlined className="mr-2" />}
										placeholder="Please confirm your password"
									/>
								</Form.Item>

								<Form.Item wrapperCol={{ offset: 32, span: 32 }} className="w-full">
									<Button type="primary" htmlType="submit" className="w-full rounded-md">
										<p className="m-auto sm:text-sm">Submit</p>
									</Button>
								</Form.Item>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</Spin>
	);
}
